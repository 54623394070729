* {
  font-family: "Poppins", sans-serif !important;
}

// ::-webkit-scrollbar-track {
//   -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
//   border-radius: 10px;
//   background-color: #f5f5f5;
// }

// ::-webkit-scrollbar {
//   // display: none;
//   height: 5px;
//   width: 5px;
//   background-color: #f5f5f5;
// }

// ::-webkit-scrollbar-thumb {
//   border-radius: 10px;
//   -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
//   background-color: #53b7e8;
// }

// ::-webkit-scrollbar {
//   // width: 4px;
//   // height: 4px;
//   // display: none;
// }

// ::-webkit-scrollbar-thumb {
//   border-radius: 10px;
//   background: var(--darkBackgroundcolor4675F1);
//   border-radius: 10px;
// }

// ::-webkit-scrollbar-track {
//   background: white;
//   border-radius: 10px;
// }

::-webkit-scrollbar {
  width: 9px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  // background-color: #888;
  background: #54afaf;
  border-radius: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.cap {
  text-transform: capitalize;
}

@import url(./variable.scss);
@import url(./header.scss);
@import url(./auth.scss);
@import url(./Responsive.scss);
@import url(./style.scss);
@import url(./home.scss);
@import url(./doctors.scss);
@import url(./medicines.scss);
@import url(./aameerResponsive.scss);
@import url(./backdrop.scss);
@import url(./paginate.scss);
@import url(./SearchFunction.scss);
@import url(./autocompleteinput.scss);
