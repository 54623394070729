.main-home-wrapper {
  .modal-for-customer-details {
    .wrapper-cards {
      margin-bottom: 12px;
      padding: 15px;
      border-radius: 20px;
      background: var(--white, #fff);
      box-shadow: 14px 17px 40px 4px rgba(112, 144, 176, 0.08);
      .accordion-button:not(.collapsed) {
        background-color: transparent;
        box-shadow: none;
      }
      .accordion-button:focus {
        box-shadow: none;
      }
      .accordion-item {
        border: none;
        color: var(--customer-light-heading, #9ab7b8);

        .accordion-body {
          margin-bottom: -35px;
        }

        // .accordion-header {
        //    margin-bottom: -35px;
        // }
      }
      .accordion-button {
        margin-left: -4px;
        padding: 2px 5px;
        color: var(--customer-light-heading, #9ab7b8);
        cursor: pointer;
        img {
          margin-right: 10px;
        }
      }
      .accordion-button::after {
        color: var(--customer-light-heading, #9ab7b8);
      }
      .accordion-button:not(.collapsed) {
        background-color: none;
        color: none;
      }
      .appointment-acc {
        display: flex;
        justify-content: space-between;

        span img {
          margin-right: 6px;
        }
      }
      .heading-close-btn {
        display: flex;
        justify-content: space-between;
      }

      .form-label {
        color: var(--customer-light-heading, #9ab7b8);
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 10px;
      }

      .photograhphy {
        border-bottom: 1px solid #1d7c7c;
        padding: 0 0 2px 0;
        width: 100%;
        margin: 5px 0 0 0;
        padding-left: 6px;
      }

      .img-select {
        display: flex;
        position: relative;

        img {
          position: absolute;
          margin-top: 6px;
        }

        span {
          margin-left: 20px;
        }
      }

      .form-select {
        background-image: url(../images/accordianActiveArow.svg);
      }

      .form-select,
      .form-control {
        color: var(--dark, #1d7c7c);
        font-size: 16px;
        font-weight: 500;
        border-bottom: 1px solid #1d7c7c;
        border-top: none;
        border-left: none;
        border-right: none;
        background-color: transparent;
        border-radius: 0;
        padding: 0 0 7px 0;

        &:focus {
          box-shadow: none;
        }

        &::placeholder {
          color: var(--dark, #1d7c7c);
          font-size: 16px;
          font-weight: 500;
        }
      }

      .form-group {
        margin-bottom: 20px;

        .resend-btn-wrapper {
          display: flex;
          justify-content: space-between;

          span {
            color: var(--customer-light-heading, #9ab7b8);
            font-size: 14px;
            font-weight: 500;
          }

          button {
            background-color: transparent;
            border: none;
            color: var(--dark, #1d7c7c);
            font-size: 14px;
            font-weight: 500;
          }
        }

        .animal-img-wrapper {
          display: flex;

          .img {
            margin-right: 10px;
            position: relative;

            .cross-icon {
            }

            .animal {
              border-radius: 16px;
              height: 80px;
              width: 80px;
            }

            button {
              background: transparent;
              border: none;
              position: absolute;
              top: 3px;
              right: 3px;
              padding: 0;
            }
          }
        }
      }

      h5 {
        color: var(--dark-headings, #1b5354);
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 30px;
      }
    }

    .buttons-wrapper-main {
      display: flex;
      justify-content: space-between;

      .add-btn-wrapper button {
        width: 140px;
        cursor: pointer;
      }
    }

    .left-section {
      .nav-link {
        display: block;
        padding: 0;
      }

      .nav-link.active {
        background-color: transparent;
        padding: 0;

        .menu-wrapper a {
          color: var(--dark, #1d7c7c);
          font-weight: 500;
        }
      }

      .active {
        color: var(--dark, #1d7c7c) !important;
        font-weight: 500 !important;
      }

      .menu-wrapper {
        a {
          text-decoration: none;
          display: flex;
          align-items: center;
          color: var(--customer-light-heading, #9ab7b8);
          font-size: 16px;
          font-weight: 400;

          img {
            margin-right: 10px;
          }
        }
      }

      .b-b {
        border-bottom: 1px solid #f2fbfa;
        padding-bottom: 15px;
        margin-bottom: 15px;
      }

      .count-main {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .count {
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          font-size: 11.667px;
          font-weight: 500;
          line-height: 13.333px;
          /* 114.286% */
          height: 20px;
          width: 20px;
          background-color: #1d7c7c;
          border-radius: 50%;
        }
      }
    }

    .add-btn-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;

      button,
      .file-upload-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 16px;
        font-weight: 500;
        height: 40px;
        // width: 130px;
        padding: 0 7px 0 7px;
        border-radius: 20px;
        background: var(--table-light-text, #43beba);
        box-shadow: 0px 2px 1.5px 0px rgba(0, 0, 0, 0.15);
        border: none;

        img {
          margin-right: 5px;
        }
      }
    }

    .submit-btn-group {
      text-align: right;

      .blank-btn {
        border-radius: 20px;
        border: 1px solid var(--table-light-text, #43beba);
        background-color: transparent;
        height: 40px;
        width: 130px;
        color: var(--table-light-text, #43beba);
        font-size: 16px;
        font-weight: 500;
      }

      .filled-btn {
        margin-left: 20px;
        border: none;
        color: #fff;
        font-size: 16px;
        font-weight: 500;
        height: 40px;
        width: 180px;
        border-radius: 20px;
        background: var(--table-light-text, #43beba);

        img {
          margin-left: 5px;
        }
      }
    }
    .save-btn {
      border: none;
      background-color: #05cd99;
    }
    .modal {
      .modal-dialog {
        .modal-content {
          font-size: 30px;
          .modal-header {
            background-color: red;
          }
        }
      }
    }
  }

  .Carousel-wrapper {
    .caro-slide-wrapper {
      img {
        width: 100%;
        // height: calc(100vh - 120px);
        max-height: 80vh;
      }
    }

    .carousel-indicators {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 2;
    }

    .carousel-indicators [data-bs-target] {
      background-color: #fff;
    }
  }

  .main-cards-wrapper {
    padding: 0 50px;

    h5 {
      color: #1b5354;
      font-size: 20px;
      font-weight: 600;
      margin-top: 20px;
    }
  }

  .Categories-wrapper {
    margin-bottom: 40px;

    .Categories-cards-wrapper {
      margin-top: 20px;

      .Categories-card.petShop {
        background-color: #000842;

        .left {
          button {
            background: #042486 !important;
          }
        }
      }

      .Categories-card.pharma {
        background-color: #21c896;

        .left {
          button {
            background: #1d7c7c !important;
          }
        }
      }

      .Categories-card {
        overflow: hidden;
        background-color: #34528a;
        border-radius: 20px;
        padding: 15px;
        display: flex;
        justify-content: space-between;
        height: 200px;

        .left {
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .top {
            label {
              color: var(--light, #eafbfb);
              font-size: 24px;
              font-weight: 600;
              display: block;
            }

            span {
              color: var(--light, #eafbfb);
              font-size: 18px;
              font-weight: 400;
            }
          }

          .bottom {
            button {
              img {
                margin-left: 10px;
                vertical-align: middle;
              }

              color: #fff;
              font-size: 16px;
              font-weight: 500;
              border: none;
              border-radius: 20px;
              background: var(--vet-light-header, #819fd8);
              height: 40px;
              width: 160px;
              /* Button */
              box-shadow: 0px 2px 1.5px 0px rgba(0, 0, 0, 0.15);
            }
          }
        }

        .right {
        }
      }
    }
  }

  .top-doctors-wrapper {
    margin-bottom: 30px;

    .heading-view-all {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .view-all {
        text-decoration: none;
        color: var(--dark-headings, #1b5354);
        font-size: 14px;
        font-weight: 500;
        display: flex;
        align-items: center;

        img {
          margin-left: 10px;
        }
      }
    }

    .doctors-card-wrapper {
      margin-top: 20px;
      position: relative;
      .doctors-card {
        padding: 10px;
        background-color: #fff;
        border-radius: 20px;
        height: 358px;
        position: relative;
        width: 80%;
        transition: background-color 0.3s ease, border-radius 0.3s ease;

        .img-section {
          width: 100%;
          height: 250px;

          .mainImgWrapper {
            width: 100%;
            height: 100%;

            .img-dr {
              height: 100%;
              width: 100%;
              object-fit: cover;
            }
          }
        }

        // width: 100%;
        // .img-section {

        // hoverEffect
        &:hover {
          transition: background-color 0.3s ease, border-radius 0.3s ease;

          &::before {
            content: "";
            position: absolute;
            top: 0;
            z-index: 1;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(17, 17, 17, 0.35);
            border-radius: 20px;
            transition: background-color 0.3s ease, border-radius 0.3s ease;

            &:hover {
              transition: background-color 0.3s ease, border-radius 0.3s ease;
            }
          }

          .book-btn {
            transition: all 0.3s ease;
            display: block;
            opacity: 1;
          }
        }

        .heart {
          position: absolute;
          top: 0;
          right: 0;
          display: inline-block;
          background-color: #fff;
          padding: 5px 7px 7px 10px;
          border-radius: 0 0 0 20px;
          z-index: 3;

          img {
            cursor: pointer;
          }
        }

        .img-dr {
          height: 220px;
          border-radius: 20px;
          width: 100%;
          object-fit: contain;
        }

        .book-btn {
          transition: all 0.3s ease;
          display: none;
          position: absolute;
          z-index: 2;
          left: 50%;
          transform: translateX(-50%);
          bottom: 20px;
          color: #fff;
          font-size: 16px;
          font-weight: 500;
          border: none;
          width: 160px;
          border-radius: 20px;
          height: 40px;
          background-color: #43beba;

          img {
            margin-left: 20px;
          }
        }
      }

      .name-rating {
        .wrapper {
          // display: flex;
          // justify-content: space-between;
          margin-top: 13px;

          .name {
            color: var(--dark, #1d7c7c);
            font-size: 16px;
            font-weight: 500;
          }

          .right {
            display: flex;
            align-items: center;

            img {
              margin-left: 5px;
            }
          }
        }

        .price-and-rating {
          margin-top: 20px;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .degree {
          color: #a3aed0;
          font-size: 14px;
          font-weight: 500;
        }
      }
    }
  }
}

.top-selling-product {
  margin-top: 40px;
}

.artical-wrapper {
  display: flex;
  align-items: center;
  margin: 30px 0;
  background: url(../images/bg_artical.png);
  background-repeat: no-repeat;
  width: 100%;
  height: 675px;
  background-size: cover;

  .main-cards-wrapper {
    width: 100%;

    .articel-card {
      border-radius: 20px;
      background-color: #fff;
      padding: 10px;
      height: 418px;

      .img-section {
        position: relative;
        transition: background-color 0.3s ease, border-radius 0.3s ease;

        &:hover {
          transition: background-color 0.3s ease, border-radius 0.3s ease;

          &::before {
            content: "";
            position: absolute;
            top: 0;
            z-index: 1;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(17, 17, 17, 0.35);
            border-radius: 20px;
            transition: background-color 0.3s ease, border-radius 0.3s ease;

            &:hover {
              transition: background-color 0.3s ease, border-radius 0.3s ease;
            }
          }

          .book-btn {
            transition: all 0.3s ease;
            display: block;
            opacity: 1;
          }
        }

        .book-btn {
          transition: all 0.3s ease;
          display: none;
          position: absolute;
          z-index: 2;
          left: 50%;
          transform: translateX(-50%);
          bottom: 20px;
          color: #fff;
          font-size: 16px;
          font-weight: 500;
          border: none;
          width: 160px;
          border-radius: 20px;
          height: 40px;
          background-color: #43beba;

          img {
            margin-left: 20px;
          }
        }

        .img-dr {
          height: 255px;
          border-radius: 20px;
          width: 100%;
          object-fit: cover;
        }
      }

      .articel-para {
        color: var(--dark-headings, #1b5354);
        font-size: 16px;
        font-weight: 600;
        margin: 15px 0 10px 0;
        height: 90px;
        overflow: auto;
      }

      .date-view {
        text-align: right;
        color: var(--dark, #1d7c7c);
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 5px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
    }
  }

  .artical-main-wrapper {
    .heading-view-all {
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      h4 {
        color: var(--light, #eafbfb);
        font-size: 20px;
        font-weight: 600;
      }

      .view-all {
        color: var(--white, #fff);
        font-size: 14px;
        font-weight: 500;
        text-decoration: none;

        img {
          margin-left: 10px;
        }
      }
    }
  }
}

#top-selling-wrapper {
  .doctors-card-wrapper .doctors-card .name-rating .wrapper .name {
    color: #1b5354;
    font-weight: 600;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2; /* Number of lines to show */
    line-clamp: 2; /* For browsers that support the standard line-clamp property */
    width: 232px;
  }

  .doctors-card-wrapper .doctors-card .name-rating .wrapper {
    margin-bottom: 5px;
  }

  .cr-price {
    margin-right: 10px;
    color: var(--table-light-text, #43beba);
    font-size: 16px;
    font-weight: 600;
  }

  .price {
    color: var(--dashboard-light-headers, #a3aed0);
    font-size: 16px;
    font-weight: 500;
    text-decoration-line: line-through;
  }

  .doctors-card-wrapper .doctors-card .img-section {
    text-align: center;
  }

  .doctors-card-wrapper .doctors-card .img-section .img-dr {
    width: 85%;
  }

  .doctors-card-wrapper .doctors-card .img-section .book-btn img {
    margin-right: 5px;
    margin-bottom: 3px;
    margin-left: 0;
  }

  #btn-added {
    background-color: #1d7c7c;
  }
}

// }

footer {
  background-color: #1d7c7c;
  padding: 30px 50px;
  margin-top: 20px;

  .footer-wrapper {
    .logo-section {
      text-align: left;

      img {
        margin-left: -15px;
        margin-top: -6px;
        margin-bottom: 10px;
      }

      p {
        margin-bottom: 0;
        color: var(--dark-bg, #f2fbfa);
        font-size: 14px;
        font-weight: 500;
      }
    }

    .menu-section {
      h6 {
        font-size: 18px;
        color: var(--white, #fff);
        font-weight: 600;
      }

      ul {
        padding: 0;
        margin: 0;

        li {
          list-style-type: none;
          margin-top: 20px;

          a {
            text-decoration: none;
            color: #7bb1b1;
            font-size: 14px;
            font-weight: 500;

            &:hover {
              transition: all 0.3s ease-in-out;
              color: var(--white, #fff);
              font-weight: 600;
            }
          }
        }
      }
    }
  }

  .social-media-wrapper {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
      .heading {
        color: var(--white, #fff);
        font-size: 18px;
        font-weight: 600;
      }
    }

    .right {
      label {
        color: var(--dark-bg, #f2fbfa);
        font-size: 14px;
        font-weight: 500;

        svg {
          margin-right: 5px;
        }
      }
    }
  }

  .social-icons-wrapper {
    margin-top: 10px;

    .same-style {
      background-color: #f2fbfa;
      display: inline-flex;
      height: 40px;
      width: 40px;
      border-radius: 50%;
      align-items: center;
      justify-content: center;
    }

    .g,
    .w,
    .p {
      margin-right: 20px;
      margin-left: 65px;
    }
  }
}

.top-scroll-btn {
  height: 45px;
  z-index: 5689;
  width: 45px;
  border-radius: 50%;
  background-color: #fff;

  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.top-scroll-btn-main {
  position: fixed;
  bottom: 40px;
  // right: 40px;
  display: flex;
  justify-content: flex-end;
}

.ProfileSetting {
  .wrapper {
    .wrapper-cards {
      margin-bottom: 12px;
      padding: 15px;
      border-radius: 20px;
      background: var(--white, #fff);
      box-shadow: 14px 17px 40px 4px rgba(112, 144, 176, 0.08);

      .inputBox {
        position: relative;
        margin-bottom: 40px;

        .profile-pic {
          position: absolute;
          height: 120px;
          width: 120px;
          left: 150px;
          transform: translateX(-50%);
          top: 20px;
          padding: 10px;
        }

        .profile-pic img {
          border-radius: 50%;
          box-shadow: 0px 0px 5px 0px #c1c1c1;
          cursor: pointer;
          width: 100px;
          height: 100px;
        }

        .emailLabel {
          color: var(--customer-light-heading, #9ab7b8);
        }
      }
      .accordion-button:not(.collapsed) {
        background-color: transparent;
        box-shadow: none;
      }
      .accordion-button:focus {
        box-shadow: none;
      }
      .accordion-item {
        border: none;
        color: var(--customer-light-heading, #9ab7b8);

        .accordion-body {
          margin-bottom: -35px;
        }

        // .accordion-header {
        //    margin-bottom: -35px;
        // }
      }
      .accordion-button {
        margin-left: -4px;
        padding: 2px 5px;
        color: var(--customer-light-heading, #9ab7b8);
        cursor: pointer;
        img {
          margin-right: 10px;
        }
      }
      .accordion-button::after {
        color: var(--customer-light-heading, #9ab7b8);
      }
      .accordion-button:not(.collapsed) {
        background-color: none;
        color: none;
      }
      .appointment-acc {
        display: flex;
        justify-content: space-between;

        span img {
          margin-right: 6px;
        }
      }
      .heading-close-btn {
        display: flex;
        justify-content: space-between;
      }

      .form-label {
        color: var(--customer-light-heading, #9ab7b8);
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 10px;
      }

      .photograhphy {
        border-bottom: 1px solid #1d7c7c;
        padding: 0 0 2px 0;
        width: 100%;
        margin: 5px 0 0 0;
        padding-left: 6px;
      }

      .img-select {
        display: flex;
        position: relative;

        img {
          position: absolute;
          margin-top: 6px;
        }

        span {
          margin-left: 20px;
        }
      }

      .form-select {
        background-image: url(../images/accordianActiveArow.svg);
      }

      .form-select,
      .form-control {
        color: var(--dark, #1d7c7c);
        font-size: 16px;
        font-weight: 500;
        border-bottom: 1px solid #1d7c7c;
        border-top: none;
        border-left: none;
        border-right: none;
        background-color: transparent;
        border-radius: 0;
        padding: 0 0 7px 0;

        &:focus {
          box-shadow: none;
        }

        &::placeholder {
          color: var(--dark, #1d7c7c);
          font-size: 16px;
          font-weight: 500;
        }
      }

      .form-group {
        margin-bottom: 20px;

        .resend-btn-wrapper {
          display: flex;
          justify-content: space-between;

          span {
            color: var(--customer-light-heading, #9ab7b8);
            font-size: 14px;
            font-weight: 500;
          }

          button {
            background-color: transparent;
            border: none;
            color: var(--dark, #1d7c7c);
            font-size: 14px;
            font-weight: 500;
          }
        }

        .animal-img-wrapper {
          display: flex;

          .img {
            margin-right: 10px;
            position: relative;

            .cross-icon {
            }

            .animal {
              border-radius: 16px;
              height: 80px;
              width: 80px;
            }

            button {
              background: transparent;
              border: none;
              position: absolute;
              top: 3px;
              right: 3px;
              padding: 0;
            }
          }
        }
      }

      h5 {
        color: var(--dark-headings, #1b5354);
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 30px;
      }
    }

    .buttons-wrapper-main {
      display: flex;
      justify-content: space-between;

      .add-btn-wrapper button {
        width: 140px;
        cursor: pointer;
      }
    }

    .left-section {
      .nav-link {
        display: block;
        padding: 0;
      }

      .nav-link.active {
        background-color: transparent;
        padding: 0;

        .menu-wrapper a {
          color: var(--dark, #1d7c7c);
          font-weight: 500;
        }
      }

      .active {
        color: var(--dark, #1d7c7c) !important;
        font-weight: 500 !important;
      }

      .menu-wrapper {
        a {
          text-decoration: none;
          display: flex;
          align-items: center;
          color: var(--customer-light-heading, #9ab7b8);
          font-size: 16px;
          font-weight: 400;

          img {
            margin-right: 10px;
          }
        }
      }

      .b-b {
        border-bottom: 1px solid #f2fbfa;
        padding-bottom: 15px;
        margin-bottom: 15px;
      }

      .count-main {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .count {
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          font-size: 11.667px;
          font-weight: 500;
          line-height: 13.333px;
          /* 114.286% */
          height: 20px;
          width: 20px;
          background-color: #1d7c7c;
          border-radius: 50%;
        }
      }
    }

    .add-btn-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;

      button,
      .file-upload-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 16px;
        font-weight: 500;
        height: 40px;
        // width: 130px;
        padding: 0 7px 0 7px;
        border-radius: 20px;
        background: var(--table-light-text, #43beba);
        box-shadow: 0px 2px 1.5px 0px rgba(0, 0, 0, 0.15);
        border: none;

        img {
          margin-right: 5px;
        }
      }
    }

    .submit-btn-group {
      text-align: right;

      .blank-btn {
        border-radius: 20px;
        border: 1px solid var(--table-light-text, #43beba);
        background-color: transparent;
        height: 40px;
        width: 130px;
        color: var(--table-light-text, #43beba);
        font-size: 16px;
        font-weight: 500;
      }

      .filled-btn {
        margin-left: 20px;
        border: none;
        color: #fff;
        font-size: 16px;
        font-weight: 500;
        height: 40px;
        width: 180px;
        border-radius: 20px;
        background: var(--table-light-text, #43beba);

        img {
          margin-left: 5px;
        }
      }
    }
  }
}

#my-appointment-wrapper {
  .wrapper-cards h5 {
    margin-bottom: 0;
  }

  .appointment-date-wrapper {
    display: flex;
    align-items: center;
    padding-left: 15px;
    margin: 20px 0 10px 0;

    .line {
      height: 1px;
      background-color: #daf0f0;
      width: calc(100% - 120px);
    }

    label {
      color: var(--dark-headings, #1b5354);
      font-size: 18px;
      font-weight: 500;
      display: inline-block;
      width: 120px;
    }
  }

  .app-details-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // border-bottom: 1px solid #daf0f0;
    padding-bottom: 10px;

    .img-dr {
      display: flex;
      align-items: center;
      width: 25%;

      img {
        height: 80px;
        width: 80px;
        border-radius: 20px;
        margin-right: 10px;
      }
    }

    .status-part {
      width: 14%;
    }

    .key {
      text-transform: capitalize;
      color: var(--dark, #1d7c7c);
      font-size: 18px;
      font-weight: 500;
      letter-spacing: -0.36px;
      margin-bottom: 5px;
    }

    .value {
      color: var(--customer-light-heading, #9ab7b8);
      font-size: 14px;
      font-weight: 500;
      letter-spacing: -0.28px;
      margin-bottom: 0;

      svg {
        margin-right: 5px;
      }
    }

    #accepted-status {
      color: var(--completed-received, #05cd99);
      font-size: 14px;
      font-weight: 500;
      letter-spacing: -0.28px;
    }

    #progress-status {
      color: var(--dark, #1d7c7c);
      font-size: 14px;
      font-weight: 500;
    }
  }

  .app-btn-wrapper {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;

    .b-c {
      border: none;
      background-color: transparent;
    }

    .full-btn.grey-btn {
      background: var(--customer-light-heading, #9ab7b8);
      font-size: 13px;
    }

    .full-btn.green-btn {
      background: var(--customer-light-heading, #43beba);
    }

    .full-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 20px;
      height: 40px;
      width: 105px;
      border: none;
      box-shadow: 0px 2px 1.5px 0px rgba(0, 0, 0, 0.15);
      color: #fff;
      font-size: 16px;
      font-weight: 500;

      img {
        margin-right: 5px;
      }
    }
  }
}

.appointment-details-wrapper {
  .wrapper-cards {
    h6 {
      color: var(--dark, #1d7c7c);
      font-size: 16px;
      font-weight: 500;
      padding-bottom: 10px;
      border-bottom: 1px solid #f6f6f6;
    }
  }

  .show-back-heading {
    margin-bottom: 30px;
    margin-top: 20px;

    img {
      margin-right: 10px;
    }

    label {
      color: var(--customer-light-heading, #9ab7b8);
      font-size: 16px;
      font-weight: 400;

      span {
        text-transform: capitalize;
        color: var(--dark, #1d7c7c);
        font-size: 16px;
        font-weight: 500;
        margin-left: 5px;
      }
    }
  }

  .key {
    color: var(--customer-light-heading, #9ab7b8);
    font-size: 14px;
    font-weight: 400;
    display: block;
    margin-bottom: 5px;
    text-transform: capitalize;
  }

  .value {
    color: var(--dark, #1d7c7c);
    font-size: 16px;
    font-weight: 500;
  }

  #completed {
    color: var(--completed-received, #05cd99);
    font-size: 14px;
    font-weight: 500;
  }

  .img-wrapper {
    display: flex;
    flex-wrap: wrap;

    .img {
      position: relative;

      .animal-img {
        height: 100px;
        width: 100px;
        border-radius: 20px;
      }

      .ic-video {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .btn-wrapper {
    border-top: 1px solid #f6f6f6;
    padding-top: 20px;
    text-align: right;
    margin-top: 20px;

    .blank-btn {
      color: var(--table-light-text, #43beba);
      font-size: 14px;
      font-weight: 500;
      border-radius: 32px;
      border: 1px solid var(--table-light-text, #43beba);
      background: transparent;
      height: 40px;
      width: 140px;
    }

    .filled-btn {
      background: var(--table-light-text, #43beba);
      border: 1px solid var(--table-light-text, #43beba);
      border-radius: 32px;
      height: 40px;
      width: 140px;
      color: var(--light-blue, #e9f5fb);
      font-size: 14px;
      font-weight: 500;
    }

    .filled-start {
      border: 1px solid var(--table-light-text, #9ab7b8);
      border-radius: 32px;
      height: 40px;
      width: 140px;
      color: var(--light-blue, #e9f5fb);
      font-size: 14px;
      font-weight: 500;
      background-color: #9ab7b8;
    }

    .btn-blank-green {
      margin-left: 20px;
      color: var(--table-light-text, #1d7c7c);
      font-size: 14px;
      font-weight: 500;
      border-radius: 32px;
      border: 1px solid var(--table-light-text, #1d7c7c);
      background: transparent;
      height: 40px;
      padding: 0 20px;

      img {
        margin-right: 5px;
      }
    }

    .waiting-text {
      label {
        color: var(--table-light-text, #1d7c7c);
      }
    }
  }

  .review-write-btn-wrapper {
    height: 60px;
    border-radius: 10px;
    background: var(--light-blue, #e9f5fb);
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      background-color: transparent;
      border: none;
      color: var(--dark, #1d7c7c);
      font-size: 16px;
      font-weight: 500;
    }
  }

  .review-details {
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 10px;
    background: var(--light-blue, #e9f5fb);
  }

  .heading-rating {
    display: flex;
    justify-content: space-between;

    .left {
    }

    .right {
      span {
        margin-left: 5px;
        color: var(--dark, #1d7c7c);
        font-size: 12px;
        font-weight: 500;
      }
    }
  }

  #tabs-app-details {
    .nav-link.active {
      color: var(--dark, #1d7c7c);
      font-weight: 600;
      border-bottom: 1px solid #1d7c7c;
      background-color: transparent;
      border-radius: 0;
    }

    .nav-link {
      color: var(--customer-light-heading, #9ab7b8);
      font-size: 16px;
      font-weight: 500;
      padding: 5px 0;
      margin-right: 20px;
    }

    .nav {
      border-bottom: 1px solid #f6f6f6;
    }

    #bg-dark-card {
      background-color: #1d7c7c;

      .app-details-wrapper {
        .key {
          color: #fff;
        }

        .value {
          color: #f2fbfa;
        }
      }

      .full-btn {
        width: 170px;
      }
    }

    #bg-light-card {
      background-color: #f2fbfa;
    }

    #reapeat-btn {
      width: 170px;
    }
  }
}

#prescription-modal {
  margin-top: 50px;

  .petfood-modal {
    position: relative;

    .filled-btn {
      border: none;
      color: #fff;
      font-size: 16px;
      font-weight: 500;
      height: 40px;
      width: 130px;
      border-radius: 20px;
      background: var(--table-light-text, #43beba);
      margin-top: 10px;
      cursor: pointer;
      position: absolute;
      z-index: 99999;
      left: 7.5%;
      bottom: 16%;
    }

    .btn-blank-green {
      position: absolute;
      top: 1%;
      right: 10px;
      border: 1px solid #1b5354;
      border-radius: 8px;
      background-color: transparent;
      color: #1b5354;

      .ic_doc_download {
        margin-left: 10px;
        height: 15px;
        width: 15px;
      }
    }
  }

  // .modal-content {
  //   pointer-events: ;
  // }

  // overflow: auto;
  .orderModalInvoice {
    .modalHeading {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      margin-bottom: 60px;

      .upperBlue {
        border-top-left-radius: 12px;
        background: url("../images/upperBlue.svg");
        height: 150px;
        width: 63%;
        background-repeat: no-repeat;

        .docName {
          text-transform: capitalize;
          position: absolute;
          top: 40px;
          left: 60px;
          color: #34528a;
          font-size: 40px;
          font-weight: 600;
        }

        .domainLab {
          position: absolute;
          top: 90px;
          left: 60px;
          font-size: 21px;
          color: #042486;
        }

        .certificateLab {
          position: absolute;
          top: 140px;
          left: 60px;
          font-size: 15px;
          color: #042486;
        }
      }

      .docSymbolBox {
        .docSymImg {
          height: 130px;
          width: 130px;
          position: absolute;
          top: 60px;
          right: 60px;
        }
      }
    }

    .modalBody {
      margin: 0px 60px;
      position: relative;

      .detailsFill {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 12px;

        .labelTxt .keyQuestion {
          font-size: 16px;
          color: #a3aed0;
        }

        .labelTxt {
          width: 21% !important;

          .widthFixed {
            font-size: 16px;
            color: #a3aed0;
          }
        }

        .form-control {
          border: none;
          border-bottom: 1px solid #34528a;
          border-radius: 0px !important;
          padding: 0px;
          font-size: 16px;
          color: #042486;
          padding-left: 10px;
          width: 100%;

          &::placeholder {
            color: #042486;
            font-size: 16px;
          }

          &:focus {
            box-shadow: none !important;
          }
        }
      }

      .addressFlex {
        display: flex;
        // align-items: center;
        width: 100%;
        margin-bottom: 12px;

        .labelTxt {
          width: 12%;

          .keyQuestion {
            font-size: 16px;
            color: #a3aed0;
          }
        }
        .text-para {
          border-bottom: 1px solid #34528a;
          color: #34528a;
        }

        .form-control {
          border: none;
          border-bottom: 1px solid #34528a;
          border-radius: 0px !important;
          padding: 0px;
          padding-left: 10px;
          width: 100%;
          font-size: 16px;
          color: #042486;

          &::placeholder {
            color: #042486;
            font-size: 16px;
          }

          &:focus {
            box-shadow: none !important;
          }
        }
      }

      .dateNdAgeFlex {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 12px;
        width: 100%;

        .ageBox {
          display: flex;
          align-items: center;
          width: 40%;

          .ageLab {
            width: 15%;
            font-size: 16px;
            color: #a3aed0;
          }

          .form-control {
            border: none;
            border-bottom: 1px solid #34528a;
            border-radius: 0px !important;
            padding: 0px;
            padding-left: 10px;
            width: 100%;
            font-size: 16px;
            color: #042486;

            &::placeholder {
              color: #042486;
              font-size: 16px;
            }

            &:focus {
              box-shadow: none !important;
            }
          }
        }

        .dateBox {
          display: flex;
          align-items: center;
          width: 55%;

          .ageLab {
            width: 15%;
            font-size: 16px;
            color: #a3aed0;
          }

          .form-control {
            border: none;
            border-bottom: 1px solid #34528a;
            border-radius: 0px !important;
            padding: 0px;
            padding-left: 10px;
            width: 100%;
            font-size: 16px;
            color: #042486;

            &::placeholder {
              color: #042486;
              font-size: 16px;
            }

            &:focus {
              box-shadow: none !important;
            }
          }
        }
      }

      .tableAndLandingBg {
        margin-top: 50px;
        position: relative;
        margin-bottom: 200px;

        .backGroundPic {
          margin-top: 78px;
          position: absolute;
          right: 0px;
          left: 0px;
          background: url("../images/bg_animal_receipt.png") no-repeat bottom
            scroll;
          background-size: contain;
          height: 380px;
          min-width: 500px;
        }

        .innerTable {
          margin-bottom: 100px;

          .modalTable {
            border: 1px solid #a3aed0;
            width: 100%;
            border-top-left-radius: 20px !important;

            thead {
              border-bottom: 1px solid #a3aed0;
              width: 100%;

              tr {
                th {
                  font-size: 14px;
                  color: #34528a;
                  padding: 20px;
                }
              }
            }

            tbody {
              tr {
                td {
                  font-size: 14px;
                  color: #34528a;
                  padding: 10px 20px;
                }
              }
            }
          }
        }
      }

      .signatureContent {
        display: flex;
        justify-content: end;
        margin-bottom: 40px;

        .sigBox {
          display: flex;
          justify-content: center;
          flex-direction: column;
          text-align: center;

          .line {
            border-bottom: 1px solid #34528a;
            margin-bottom: 10px;

            img {
              height: 80px;
              width: 120px;
            }
          }

          .signaturePad {
            border-bottom: 1px solid #34528a;
            height: 100px;
            width: 215px;
            margin-bottom: 10px;
            cursor: pointer;
          }

          .belowTxt {
            font-size: 16px;
            color: #042486;
          }
        }
      }
    }

    .belowDetails {
      .imgWrapper {
        height: auto;
        background: url("../images/bottomBlue.svg");
        width: 100%;
        // height: 100px;

        .heightAuto {
          height: inherit;
        }

        .txt {
          border-right: 2px solid #34528a;
          text-align: center;
          padding: 15px;

          .innerTxt {
            font-size: 20px;
            color: #34528a;
            padding-right: 20px;
          }
        }

        .allCOntact {
          padding: 20px 20px 20px 30px;

          .emailAndCall {
            display: flex;
            align-items: center;

            .callNnumber,
            .emialInn {
              display: flex;
              align-items: center;
              margin-right: 10px;
              margin-bottom: 20px;

              img {
                margin-right: 5px;
              }

              .numberInn {
                font-size: 16px;
                color: #34528a;
              }
            }
          }

          .locationInn {
            display: flex;
            align-items: center;

            img {
              margin-right: 10px;
            }

            .locationTxt {
              font-size: 16px;
              color: #34528a;
            }
          }
        }
      }
    }
  }

  .top-btns {
    position: absolute;
    width: 100%;
    top: -40px;
  }

  .modal-body {
    padding: 0;
  }

  .modal-content {
    // overflow-x: auto;
    min-width: 816px;
  }
}

#RequestAmbulancComp {
  .show-back-heading {
    margin: 20px 0;
  }

  .location-btn {
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    border-radius: 20px;
    background: var(--customer, #43beba);
    height: 40px;
    padding: 0 20px;
    box-shadow: 0px 2px 1.5px 0px rgba(0, 0, 0, 0.15);
    border: none;

    img {
      margin-right: 5px;
    }
  }

  .ambulance-card-wrapper {
    .ambulance-card {
      padding: 10px;
      border-radius: 20px;
      background: #fff;
      box-shadow: 14px 17px 40px 4px rgba(112, 144, 176, 0.08);

      .img {
        img {
          width: 100%;
          height: 190px;
        }
      }

      .pharma-name-wrapper {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;

        label {
          color: var(--dark, #1d7c7c);
          font-size: 14px;
          font-weight: 500;
          letter-spacing: -0.28px;
          margin-bottom: 5px;

          img {
            margin-right: 5px;
          }
        }

        p {
          margin-bottom: 0;
          color: var(--customer-light-heading, #9ab7b8);
          font-size: 12px;
          font-weight: 400;
          letter-spacing: -0.24px;

          img {
            margin-right: 5px;
          }
        }
      }

      .book-btn {
        margin-top: 15px;
        width: 100%;
        border-radius: 20px;
        background: var(--customer, #43beba);

        box-shadow: 0px 2px 1.5px 0px rgba(0, 0, 0, 0.15);
        color: #fff;
        font-size: 16px;
        font-weight: 500;
        border: none;
        height: 40px;

        img {
          margin-left: 5px;
        }
      }
    }
  }
}

.AmbulanceBook-main {
  .rewiew-wrapper {
    h4 {
      color: var(--dark, #1d7c7c);
      font-size: 18px;
      font-weight: 600;
      letter-spacing: -0.36px;
    }

    .hdng {
      border-bottom: 1px solid #9ab7b8;
    }
  }

  .booking-card-wrapper {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    border-radius: 20px;
    background: var(--white, #fff);
    padding: 20px;
    display: flex;
    justify-content: space-between;

    .left {
      display: flex;

      p {
        margin-bottom: 0;
      }

      .left-left {
        .img {
          img {
            height: 150px;
            width: 180px;
            border-radius: 15px;
          }

          margin-right: 20px;
        }
      }

      .left-right {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .img-wrapper-all-ambu {
          img {
            margin-right: 10px;
            border-radius: 10px;
            height: 50px;
            width: 50px;
          }
        }

        h4 {
          color: var(--dark, #1d7c7c);
          font-size: 18px;
          font-weight: 600;
          letter-spacing: -0.36px;
        }

        .value {
          color: var(--customer-light-heading, #9ab7b8);
          font-size: 14px;
          font-weight: 500;
          letter-spacing: -0.28px;

          img {
            margin-right: 5px;
          }
        }
      }
    }

    .right {
      display: flex;
      justify-content: space-around;
      flex-direction: column;

      p {
        margin-bottom: 0;
        color: var(--customer-light-heading, #9ab7b8);
        font-size: 14px;
        font-weight: 500;
        letter-spacing: -0.28px;

        img {
          margin-right: 10px;
        }
      }

      .book-btn {
        padding: 0 30px;
        height: 40px;
        border: none;
        border-radius: 20px;
        background: var(--customer, #43beba);
        box-shadow: 0px 2px 1.5px 0px rgba(0, 0, 0, 0.15);
        color: #fff;
        font-size: 16px;
        font-weight: 500;

        img {
          margin-left: 10px;
        }
      }
    }
  }

  #booking-card-wrapper {
    display: block;

    .rewiew-wrapper-content {
      margin-top: 20px;
      display: flex;

      .rewiew-wrapper-left {
        img {
          height: 80px;
          width: 80px;
          border-radius: 20px;
        }
      }

      .rewiew-wrapper-right {
        .heading-star {
          display: flex;
          justify-content: space-between;

          img {
            margin-right: 5px;
          }

          span {
            color: var(--dark, #1d7c7c);
            font-size: 18px;
            font-weight: 500;
            letter-spacing: -0.36px;
          }
        }

        margin-left: 15px;
        border-radius: 10px;
        background: var(--dark-bg, #f2fbfa);
        padding: 20px 10px;
        width: 100%;

        label {
          margin-bottom: 10px;
          color: var(--dark-headings, #1b5354);
          font-size: 18px;
          font-weight: 500;
        }

        p {
          margin-bottom: 0;
          color: var(--dark, #1d7c7c);
          font-size: 16px;
          font-weight: 500;
        }

        span {
          color: var(--customer-light-heading, #9ab7b8);
          font-size: 14px;
          font-weight: 500;
          margin-bottom: 10px;
        }

        .nested-content {
          margin-top: 10px;
          display: flex;

          img {
            margin-right: 10px;
            height: 60px;
            width: 60px;

            border-radius: 15px;
          }
        }
      }
    }

    .show-all-btn {
      text-align: center;
      margin-top: 20px;

      button {
        border-radius: 20px;
        background: var(--customer, #43beba);

        box-shadow: 0px 2px 1.5px 0px rgba(0, 0, 0, 0.15);
        color: #fff;
        font-size: 16px;
        border: none;
        font-weight: 500;
        height: 40px;
        padding: 0 30px;
      }
    }
  }
}

// Tracking map styles
.trackingBox {
  .leftCard {
    background-color: #fff;
    border-radius: 12px;
    padding: 12px;
    margin-bottom: 10px;

    .headTxt {
      border-bottom: 1px solid #9ab7b8;
      margin-bottom: 10px;

      h1 {
        color: #21c896;
        font-size: 18px;
      }
    }

    .fieldsBox {
      .formBox {
        margin-bottom: 8px;

        .key {
          font-size: 18px;
          color: #21c896;
          margin-right: 10px;
          width: 22%;
          // text-align: end;
        }

        .value {
          font-size: 18px;
          color: #21c896;
        }
      }
    }
  }

  .rightCard {
    background-color: #fff;
    border-radius: 12px;
    padding: 12px;
    margin-bottom: 10px;

    .headTxt {
      border-bottom: 1px solid #9ab7b8;
      margin-bottom: 10px;

      h1 {
        color: #21c896;
        font-size: 18px;
      }
    }

    .fieldsBox {
      .formBox {
        margin-bottom: 8px;

        .key {
          font-size: 18px;
          color: #21c896;
          margin-right: 10px;
          width: 22%;
          // text-align: end;
        }

        .value {
          font-size: 18px;
          color: #21c896;
        }
      }
    }
  }

  .mapCard {
    background-color: #fff;
    border-radius: 12px;
    padding: 12px;
  }
}

#sucessfull-wrapper {
  .p-method {
    display: flex;
    justify-content: space-between;

    label {
      color: var(--table-light-text, var(--customer, #43beba));
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    p {
      margin-bottom: 0;
      color: var(--dark, #1d7c7c);
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      width: 172px;
    }
  }

  #btns-grp {
    margin-top: 20px;

    .btn-blank {
      border-radius: 20px;
      border: 1px solid var(--table-light-text, #43beba);
      background-color: transparent;
      height: 40px;
      width: 200px;
      color: var(--table-light-text, #43beba);
      font-size: 16px;
      font-weight: 500;
    }

    .btn-filled {
      margin-left: 20px;
      border: none;
      color: #fff;
      font-size: 16px;
      font-weight: 500;
      height: 40px;
      width: 200px;
      border-radius: 20px;
      background: var(--table-light-text, #43beba);
    }
  }

  .booking-card-wrapper {
    padding: 40px;
  }

  text-align: center;
  max-width: 680px;

  .top-img {
    h4 {
      margin-top: 20px;
      color: var(--dark-headings, #1b5354);
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    img {
      height: 100px;
      width: 100px;
    }
  }

  .successfull-detail {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;

    p {
      margin-bottom: 0;
    }

    .left {
      img {
        height: 60px;
        width: 60px;
        margin-right: 10px;
      }

      .left-right {
        display: flex;
        justify-content: space-around;
        flex-direction: column;
      }
    }

    .right {
      p {
        color: var(--dark, #1d7c7c);
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        /* 200% */
        letter-spacing: -0.24px;
      }
    }

    .key {
      margin-bottom: 5px;
      color: var(--dark, #1d7c7c);
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.36px;
    }

    .value {
      color: var(--customer-light-heading, #9ab7b8);
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.28px;
    }
  }
}

.petient-booking-detail-main {
  #btns-grp {
    text-align: right;
    margin-top: 20px;

    .btn-blank {
      border-radius: 20px;
      border: 1px solid var(--table-light-text, #43beba);
      background-color: transparent;
      height: 40px;
      width: 130px;
      color: var(--table-light-text, #43beba);
      font-size: 16px;
      font-weight: 500;
    }

    .btn-filled {
      margin-left: 20px;
      border: none;
      color: #fff;
      font-size: 16px;
      font-weight: 500;
      height: 40px;
      width: 130px;
      border-radius: 20px;
      background: var(--table-light-text, #43beba);
    }
  }

  #payment-option {
    .btn-filled {
      width: 170px;
    }
  }

  .form-section {
    .form-check {
      display: flex;
      align-items: center;

      .form-check-input {
        cursor: pointer;
        margin-top: 0;
        height: 16px;
        width: 16px;
        border-color: #1d7c7c;
      }

      .form-check-input:checked[type="radio"] {
        background-image: url(../images/ic_radio_check.svg);
        background-size: 10px;
      }

      .form-check-input:checked {
        border-color: #1d7c7c;
        background-color: transparent;
      }

      label {
        color: var(--dark, #1d7c7c);
        font-size: 16px;
        font-weight: 500;
        margin-left: 5px;
      }

      .form-check-input:focus {
        box-shadow: none;
      }
    }

    .form-label {
      color: var(--customer-light-heading, #9ab7b8);
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 10px;
    }

    .form-control,
    .form-select {
      color: var(--dark, #1d7c7c);
      font-size: 16px;
      font-weight: 500;
      border-bottom: 1px solid #1d7c7c;
      border-top: none;
      border-left: none;
      border-right: none;
      background-color: transparent;
      border-radius: 0;
      padding: 0 0 7px 0;

      &:focus {
        box-shadow: none;
      }

      &::placeholder {
        color: var(--dark, #1d7c7c);
        font-size: 16px;
        font-weight: 500;
      }
    }
  }

  .form-select {
    background-image: url(../images/accordianActiveArow.svg);
  }

  #booking-right {
    .charge-wrapper {
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;

      .key {
        color: var(--customer-light-heading, #9ab7b8);
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      .value {
        color: var(--dark, #1d7c7c);
        text-align: right;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }

    .left .left-left .img {
      margin-right: 10px;
    }

    .left .left-left .img img {
      height: 60px;
      width: 60px;
      border-radius: 10px;
    }

    display: block;

    #card-item {
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
    }
  }
}

.map-with-details-wrapper {
  .map {
    .map-main {
      height: 150px;

      .leaflet-container {
        border-radius: 10px;
        height: 100%;
      }
    }
  }

  .wrapper-cards {
    display: flex;

    .details-amb {
      width: 100%;
    }

    .left-amb-img {
      margin-right: 10px;

      img {
        height: 80px;
        width: 80px;
      }
    }
  }

  .ambulance-date-wrapper {
    .line {
      height: 1px;
      background-color: #daf0f0;
      width: calc(100% - 120px);
    }

    label {
      color: var(--dark-headings, #1b5354);
      font-size: 18px;
      font-weight: 500;
      display: inline-block;
      width: 120px;
    }

    display: flex;
    align-items: center;
    padding-left: 15px;
    margin: 20px 0 10px 0;
  }
}

.main-MyTransactionComp {
  .pagination-wrapper {
    margin-top: 20px;

    label {
      color: var(--Customer-Light-Heading, #9ab7b8);
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      /* 150% */
    }
  }

  .table-wrapper-main {
    .t-wrapper-inner {
      overflow-x: auto;

      table {
        width: 100%;
        min-width: 700px;

        .t-img {
          height: 40px;
          width: 40px;
          border-radius: 50%;
          margin-right: 10px;
        }

        thead {
          tr {
            border-bottom: 1px solid #e9f5fb;
          }
        }

        .inv-btn {
          background: transparent;
          border: none;
        }

        td {
          padding: 5px 10px;
          color: var(--Dark, #1d7c7c);
          font-size: 12px;
          font-weight: 400;
        }

        th {
          padding: 5px 10px;
          color: var(--Customer-Light-Heading, #9ab7b8);
          font-size: 12px;
          font-weight: 400;
        }
      }
    }
  }

  .wrapper-cards {
    h5 {
      margin-bottom: 0 !important;
    }
  }

  .heading-close-btn {
    align-items: center;
  }

  #transaction-search {
    display: flex;
    align-items: center;

    .form-control {
      margin-right: 20px;
      width: 350px;
      height: 40px;
      border-radius: 20px;
      border: 1px solid var(--Customer-Light-Heading, #9ab7b8);
      background: var(--Dark-Bg, #f2fbfa);
      padding: 5px 10px;

      &::placeholder {
        color: #9ab7b8;
      }
    }
  }
}

#prescription-modal {
  #medical-prescription {
    position: relative;

    .btn-blank-green {
      position: absolute;
      top: 1%;
      right: 10px;
      border: 1px solid #1b5354;
      border-radius: 8px;
      background-color: transparent;
      color: #1b5354;

      .ic_doc_download {
        margin-left: 10px;
        height: 15px;
        width: 15px;
      }
    }

    .tableAndLandingBg .backGroundPic {
      height: 100%;
      margin-top: 20px;
    }

    .total-amount-pay-wrapper {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      margin-bottom: 150px;

      p {
        color: var(--Dashboard-Headers, #042486);
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 10px;
      }

      .right-total {
        width: 300px;
        padding: 10px;
        border-radius: 20px;
        border: 1px solid var(--Dashboard-Light-headers, #a3aed0);

        .sub-t,
        .tax,
        .total {
          display: flex;
          justify-content: space-between;
        }

        .total {
          border-top: 1px solid #a3aed0;
          padding-top: 10px;
        }
      }
    }

    .form-control {
      border-bottom: 1px solid #21c896;
    }

    .tableAndLandingBg {
      .innerTable {
        margin-bottom: 0;
      }

      margin-top: 25px;
      margin-bottom: 0;

      .invoice-no {
        margin-bottom: 10px;
        text-align: right;

        label {
          color: var(--Dashboard-Headers, #042486);
          font-size: 18px;
          font-weight: 500;
        }
      }
    }

    .upperBlue {
      border-top-left-radius: 12px;
      background-image: url(../images/bg_green_pre_top.svg);

      .certificateLab {
        top: 135px;
      }

      .docName {
        color: #21c896;
      }
    }

    .belowDetails .imgWrapper {
      height: auto;
      background-image: url(../images/bg_green_pre_bot.svg);

      .txt {
        border-right: 2px solid #21c896;
      }

      .allCOntact .emailAndCall .emialInn .numberInn,
      .allCOntact .locationInn .locationTxt,
      .allCOntact .emailAndCall .callNnumber .numberInn,
      .txt .innerTxt {
        color: #21c896;
      }
    }
  }
}

#SupportComp {
  .heading-close-btn.msg-submit {
    text-align: right;
    margin-top: 20px;
  }

  .form-support {
    .key {
      color: var(--Customer-Light-Heading, #9ab7b8);
      font-size: 16px;
      font-weight: 500;
    }
  }

  .wrapper-cards {
    h6 {
      color: var(--Dark-Headings, #1b5354);
      font-size: 18px;
      font-weight: 500;
      padding-bottom: 10px;
      border-bottom: 1px solid #f2fbfa;
    }
  }

  .app-details-wrapper {
    border-bottom: none;
    padding-bottom: 0;
  }

  #resolved {
    color: var(--completed-received, #05cd99);
    font-size: 14px;
    font-weight: 500;
  }

  #progress {
    color: #a9a95a;
    font-size: 14px;
    font-weight: 500;
  }

  .heading-close-btn {
    align-items: center;

    .blank-btn {
      border-radius: 20px;
      border: 1px solid var(--table-light-text, #43beba);
      background-color: transparent;
      height: 40px;
      width: 130px;
      color: var(--table-light-text, #43beba);
      font-size: 16px;
      font-weight: 500;
      margin-right: 10px;
    }

    button {
      height: 40px;
      padding: 0 20px;
      color: #fff;
      font-size: 16px;
      font-weight: 500;
      border-radius: 20px;
      background: var(--customer, #43beba);

      /* Button */
      box-shadow: 0px 2px 1.5px 0px rgba(0, 0, 0, 0.15);
      border: none;
    }
  }
}

.booking-card-wrapper.support {
  box-shadow: none;
  background-color: transparent;
  padding: 0;

  .rewiew-wrapper-right {
    background: #fff !important;
  }
}

// My Orders Section Styles
.mainOrderSection {
  .headingSearch {
    background-color: #fff;
    padding: 10px 20px;
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .headTxt {
      color: #1b5354;
      font-size: 18px;
      margin-bottom: 0px !important;
    }

    .calendarFiled {
      display: flex;

      .form-control {
        width: 350px;
        border-radius: 20px;
        background-color: #f2fbfa;
        border: 1px solid #9ab7b8;
        color: #9ab7b8;
        padding-left: 20px;
        margin-right: 20px;

        &::placeholder {
          color: #9ab7b8;
        }

        &:focus {
          box-shadow: none;
        }
      }
    }
  }

  .completeOrderTracker {
    margin-bottom: 40px;

    .dateBorder {
      display: flex;
      align-items: center;
      padding: 0px 20px;
      margin-bottom: 10px;

      .DateLab {
        color: #1b5354;
        font-size: 18px;
        margin-right: 10px;
        width: 120px;
      }

      .BorderLine {
        width: calc(100% - 120px);
        border: 1px solid #daf0f0;
        // height: 1px;
      }
    }

    .orderDetails {
      padding: 20px;
      background-color: #fff;
      border-radius: 20px;
      margin-bottom: 20px;

      .headFlex {
        display: flex;
        justify-content: space-around;
        align-items: center;
        border-bottom: 1px solid #f2fbfa;
        margin-bottom: 10px;
      }

      .mediFlex {
        display: flex;
        align-items: center;
        width: 25%;

        .mediBox {
          margin-right: 10px;

          img {
            width: 80px;
            height: 50px;
            object-fit: cover;
          }
        }

        .detailBox {
          a {
            text-decoration: none;
          }

          h1 {
            color: #1d7c7c;
            font-size: 18px;
            margin-bottom: 5px;
          }

          label {
            color: #9ab7b8;
            font-size: 16px;
          }
        }
      }

      .keyValueFlex {
        display: flex;
        flex-direction: column;
        width: 18%;

        .key {
          color: #1d7c7c;
          font-size: 18px;
          margin-bottom: 10px;
        }

        .textSuccess {
          color: #05cd99;
          font-size: 16px;
        }

        .textDanger {
          color: #ee5d50;
          font-size: 16px;
        }

        .kevalue {
          color: #9ab7b8;
          font-size: 16px;
        }
      }

      .bottomBtns {
        text-align: end;

        img {
          margin-right: 30px;
        }

        .locationBtn {
          border: none;
          height: 42px;
          width: 200px;
          border-radius: 20px;
          background-color: #43beba;
          color: #fff;
          font-size: 16px;

          img {
            margin-right: 5px;
          }
        }

        .viewnBtn {
          border: none;
          height: 42px;
          width: 110px;
          border-radius: 20px;
          background-color: #43beba;
          color: #fff;
          font-size: 16px;
          margin-right: 30px;

          img {
            margin-right: 0px !important;
          }
        }
      }
    }
  }
}

// My Orders Details
.myOrders {
  .personalOrders {
    .backAndResult {
      margin-bottom: 10px;

      .Arrow {
        margin-right: 20px;
      }

      .keyValues {
        color: #9ab7b8;
        font-size: 16px;

        .diffColor {
          color: #1d7c7c;
          font-size: 16px;
        }
      }
    }

    .mainCard {
      background-color: #fff;
      border-radius: 20px;
      padding: 20px;
      margin-bottom: 20px;

      .addressDetails {
        border-right: 1px solid #f2fbfa;
        height: 110px;

        .nameOfAddress {
          color: #1d7c7c;
          font-size: 18px;
        }

        label {
          color: #9ab7b8;
          font-size: 16px;
        }
      }

      .moreActionBtns {
        .nameTag {
          color: #1d7c7c;
          font-size: 18px;
        }

        .reOrderBtn {
          border: 1px solid #1d7c7c;
          width: 142px;
          height: 42px;
          background-color: transparent;
          color: #1d7c7c;
          font-size: 16px;
          border-radius: 20px;
          margin-right: 20px;
        }

        .downloadBtn {
          border: none;
          width: 210px;
          height: 42px;
          background-color: #43beba;
          color: #fff;
          border-radius: 20px;
        }
      }
    }

    .completeOrderTracker {
      background-color: #fff;
      border-radius: 20px;
      padding: 20px;
      margin-bottom: 20px;

      .orderDetails {
        .mediFlex {
          display: flex;
          align-items: center;

          .mediBox {
            margin-right: 10px;

            img {
              width: 70px;
              height: 50px;
              object-fit: cover;
            }
          }

          .detailBox {
            a {
              text-decoration: none;
            }

            h1 {
              color: #1d7c7c;
              font-size: 18px;
              margin-bottom: 5px;
            }

            p {
              color: #9ab7b8;
              font-size: 16px;
              margin-bottom: 5px !important;
            }

            .Dollar {
              color: #1d7c7c;
              font-size: 11px;
              margin-bottom: 0px !important;
            }
          }
        }

        .totalAmt {
          color: #1d7c7c;
          font-size: 18px;
          margin-bottom: 5px;
        }

        .Dollar {
          color: #1d7c7c;
          font-size: 14px;
          margin-bottom: 0px !important;
        }

        .rateBtn {
          .rateReview {
            border: none;
            background-color: #f2fbfa;
            border-radius: 20px;
            width: 210px;
            height: 42px;
            color: #1d7c7c;
          }
        }

        .mainStepper {
          position: relative;

          .stepper {
            list-style: none;
            display: flex;
            flex-direction: row;
            padding: 0;

            .step {
              position: relative;
              width: 100%;
              display: flex;
              flex-direction: column;
              align-items: center;

              &.active {
                .dot {
                  background-color: #1d7c7c;
                }

                .lower-text {
                  color: #1d7c7c;
                }

                .upper-text {
                  color: #1d7c7c;
                }

                &:after {
                  background-color: #1d7c7c;
                }
              }

              &:last-child {
                &:after {
                  width: 50%;
                  transform: translateX(-50%);
                }
              }

              &:first-child {
                &:after {
                  width: 50%;
                  transform: translateX(50%);
                }
              }

              &:after {
                content: "";
                width: 100%;
                height: 3px;
                position: absolute;
                top: 29px;
                background-color: #9ab7b8;
                z-index: 0;
              }

              .upper-text {
                color: #9ab7b8;
                font-size: 14px;
                font-weight: 500;
                letter-spacing: -0.28px;
                height: 20px;
                display: block;
              }

              .lower-text {
                color: var(--Customer-Light-Heading, #9ab7b8);
                text-align: center;
                font-size: 14px;
                font-weight: 500;
                letter-spacing: -0.28px;
                display: block;
              }

              .dot {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                position: relative;
                z-index: 1;
                display: inline-block;
                background-color: #9ab7b8;
              }
            }
          }

          .statusChecker {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .position1 {
              position: absolute;
              font-size: 14px;
              color: #1d7c7c;
              top: 0px;
              left: 60px;
            }

            .position2 {
              position: absolute;
              font-size: 14px;
              color: #1d7c7c;
              top: 0px;
              left: 262px;
            }

            .position3 {
              position: absolute;
              color: #1d7c7c;
              font-size: 14px;
              top: 0px;
              right: 230px;
            }

            .position4 {
              position: absolute;
              color: #1d7c7c;
              font-size: 14px;
              top: 0px;
              right: 82px;
            }
          }

          .StepButtonContent-d1-1-2-7,
          .StepButtonContent-d3-0-2-13,
          .StepButtonContent-d1-0-2-7,
          .StepButtonContent-d7-0-2-27 {
            display: none !important;
          }

          .StepButton-d0-0-2-6.completed {
            background-color: #1d7c7c !important;
          }

          .StepButton-d2-0-2-12.active {
            background-color: #1d7c7c !important;
          }

          .StepButton-d4-0-2-20,
          .StepButton-d6-0-2-26 {
            background-color: #9ab7b8 !important;
          }

          .StepButton-d2-0-2-12,
          .StepButton-d4-0-2-20,
          .StepButton-d6-0-2-26,
          .StepButton-d0-0-2-6 {
            height: 20px !important;
            width: 20px !important;
            z-index: 10 !important;
          }

          .ConnectorContainer-d0-0-2-16,
          .ConnectorContainer-d2-0-2-22,
          .ConnectorContainer-d4-0-2-28 {
            top: calc((2em - 1px) / 4);
            left: calc((-50% + 1.2em) - 10px);
            right: calc((50% + 1.2em) - 10px);
            position: absolute;
          }

          .Connector-d5-0-2-29,
          .Connector-d3-0-2-23 {
            border: 3px solid #9ab7b8 !important;
          }

          .Connector-d1-0-2-17 {
            border: 3px solid #1d7c7c !important;
          }

          .StepButtonContent-d5-0-2-21 {
            display: none !important;
          }

          .Label-d1-0-2-11,
          .Label-d3-0-2-19,
          .Label-d5-0-2-25,
          .Label-d7-0-2-31 {
            color: #1d7c7c;
            font-size: 14px;
          }
        }
      }
    }

    .reviewWrittingBox {
      background-color: #fff;
      border-radius: 20px;
      padding: 20px;

      .headingBox {
        border-bottom: 1px solid #f2fbfa;
        margin-bottom: 20px;

        h1 {
          font-size: 16px;
          color: #1d7c7c;
        }
      }

      .contentBox {
        border-bottom: 1px solid #f2f2f2;
        margin-bottom: 20px;

        .inputBox {
          padding-bottom: 20px;

          .suggestionHead {
            color: #9ab7b8;
            font-size: 16px;
          }

          .form-control {
            border: none;
            border-bottom: 2px solid #1d7c7c;
            border-radius: 0px;
            padding-left: 0px;

            &::placeholder {
              color: #1d7c7c;
            }
          }
        }

        .starBox {
          img {
            margin-right: 10px;
          }
        }
      }

      .belowBtns {
        text-align: end;

        .cancel {
          border: 1px solid #1d7c7c;
          height: 42px;
          width: 140px;
          background-color: transparent;
          color: #1d7c7c;
          border-radius: 20px;
          margin-right: 10px;
        }

        .submit {
          border: none;
          height: 42px;
          width: 140px;
          background-color: #1d7c7c;
          color: #fff;
          border-radius: 20px;
        }
      }
    }
  }
}
