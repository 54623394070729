.lightMode {
    // BACKGROUND-COLORS VARIABLES
    --darkBackgroundcolor659c9c: #659c9c;
    --darkBackgroundcolor1D7C7C: #1D7C7C;
    //  TEXT-COLOR VARIABLES
    --lightGreyColor999999: #999999;
    --darkBlackColor000: #000;
    --darkBlueColor4675F1: #4675F1;
    --lightGreytxtADADAD : #ADADAD;
    --whiteTxtColorfff : #fff;
    --greyTextColorB5B5B5 : #B5B5B5;
    --lightGreenColorE9F5FB : #E9F5FB;
    --lightWhiteColorFFFFFF : #FFFFFF;
}

// For Future use this variables are created
.darkMode {}