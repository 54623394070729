.center-loading {
  width: 100%;
  position: fixed;
  // margin-left: 50%;
  text-align: center;
  top: 50%;
}

.spinner-border {
  display: flex;
}

#backdrop {
  position: fixed;
  // top: 0;
  width: 100vw;
  height: 100%;
  z-index: 99999;
  background-color: rgb(0, 0, 0, 0.2);
}