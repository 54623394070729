.screens-wrapper {
  max-width: 100%;
  // max-width: 1440px;
}
.capital {
  text-transform: capitalize;
}
.err-msg {
  font-size: .875em;
  color: #dc3545;
}
#cx-main {
  background-color: #f2fbfa;
  height: 100%;
  min-height: calc(100vh - 120px);
}

.custom-image-magnify .react-image-magnify__large-image {
  z-index: 9999; // Set the desired zIndex value
}

.cx-main-wrapper {
  padding: 20px 40px;
}

.detailCardsBtns {
  // position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // gap: 63px;
  bottom: 10px;
  padding: 0 5px;
  margin-top: 20px;

  .DetailsBtn {
    text-decoration: none;

    button {
      border: 1px solid #43beba;
      width: 205px;
      background-color: transparent;
      height: 40px;
      border-radius: 20px;
      color: #43beba;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        margin-left: 7px;
      }
    }
  }

  .bookBtn {
    border: none;
    width: 205px;
    background-color: #43beba;
    height: 40px;
    border-radius: 20px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      margin-left: 7px;
    }
    // text-decoration: none;

    // button {

    // }
  }
}

.bookBtn {
  border: none;
  width: 205px;
  background-color: #43beba;
  height: 40px;
  border-radius: 20px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    margin-left: 10px;
  }
}

.showMoreBtn {
  border: none;
  background-color: #43beba;
  color: #fff;
  width: 130px;
  height: 40px;
  border-radius: 20px;
}

.carousel-control-next,
.carousel-control-prev {
  display: none !important;
}

.carousel-indicators [data-bs-target] {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: gold;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  background-image: none;
}

.form-control.is-valid,
.was-validated .form-control:valid {
  background-image: none;
}

.erroMsg {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.file-label input[type="file"] {
  display: none;
}

#ChattingVideoComp {
  .send-text-sec {
    .wrap-btn-send {
      display: flex;
      background-color: #e9f5fb;
      padding: 5px;
      border-radius: 80px;

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #43beba;
        height: 40px;
        width: 40px;
        border-radius: 50%;
        border: none;
      }

      .form-control {
        background-color: transparent;
        border: none;

        &:focus {
          box-shadow: none;
        }

        &::placeholder {
          color: #8d8f98;
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22.15px;
          /* 158.218% */
        }
      }
    }
  }

  h4 {
    color: #43beba;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: -0.32px;
    padding-bottom: 10px;
    border-bottom: 1px solid #f6f6f6;
  }

  .left-video {
    border-radius: 20px;
    background-color: #fff;
    padding: 20px;
  }

  .right-chating {
    border-radius: 20px;
    background-color: #fff;
    padding: 10px;

    .chatting-section {
      height: calc(100vh - 340px);
      margin: 15px 0 0;
      overflow-y: auto;
    }

    .message-main {
      margin-bottom: 10px;

      .receive-msg {
        display: flex;
        margin-bottom: 5px;

        .msg-with-img {
          margin-right: 5px;

          img {
            height: 40px;
            width: 40px;
            border-radius: 50%;
          }
        }

        .right-receive-msg {
          .msg-bg {
            border-radius: 7px;
            background: var(--Light-Blue, #e9f5fb);
            padding: 10px;
          }

          display: block;
          text-align: left;

          .senderName {
            color: var(--Dashboard-Light-headers, #a3aed0);
            font-family: Poppins;
            font-size: 10px;
            font-style: normal;
            font-weight: 500;
          }

          .receive-msg-main {
            .msg-text {
              color: var(--Vet, #34528a);
              font-family: Poppins;
              font-size: 14px;
              font-style: normal;
              margin-bottom: 0;
              font-weight: 500;
            }
          }
        }
      }

      .send-msg {
        text-align: right;
        display: flex;
        flex-direction: row-reverse;
        margin-bottom: 5px;

        .msg-with-img {
          img {
            height: 40px;
            width: 40px;
            border-radius: 50%;
            margin-left: 5px;
          }
        }

        .send-msg-main {
          .msg-bg {
            border-radius: 10px;
            background: var(--Light-Blue, #e9f5fb);
            padding: 10px;
          }

          .senderName {
            color: var(--Dashboard-Light-headers, #a3aed0);
            font-family: Inter;
            font-size: 10px;
            font-style: normal;
            font-weight: 500;
          }

          .msg-text {
            margin-bottom: 0;
            color: var(--Vet, #34528a);
            font-family: Inter;
            font-size: 15.301px;
            font-style: normal;
            font-weight: 500;
          }
        }
      }

      .time-date-sms-send {
        display: flex;
        flex-direction: row-reverse;
        color: var(--Dashboard-Light-headers, #a3aed0);
        font-family: Poppins;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        margin-top: 5px;
      }

      .time-date-sms-recieve {
        color: var(--Dashboard-Light-headers, #a3aed0);
        font-family: Poppins;
        font-size: 10px;
        font-style: normal;
        margin-top: 5px;
        font-weight: 400;
      }
    }
  }

  .left-video {
    .video-section {
      .left-video {
        :nth-child(2) {
          background-color: #1d7c7c !important;
        }

        :nth-child(1) {
          div {
            &:nth-child(1) {
              div {
                &:nth-child(1) {
                  div {
                    &:nth-child(1) {
                      flex: 1 1 0% !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// Prime react modal styles
.p-dialog {
  border: none;
  box-shadow: none;
}

.p-dialog .p-dialog-header {
  display: none;
}

.p-dialog .p-dialog-content {
  padding-top: 1.5rem;
  border-radius: 20px;
}

.p-dialog-enter-done {
  transform: translate(-15px, 20px) !important;
}

.p-dialog .p-dialog-header,
.p-dialog .p-dialog-content {
  background: #fff;
  color: #43beba;
  width: max-content;

  .insideCallSec {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: max-content;

    img {
      height: 140px;
      width: 140px;
      border-radius: 50%;
    }

    .detailsSec {
      p {
        font-size: 18px;
      }
    }

    .buttonsFlex {
      margin-top: 20px;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      width: 100%;

      .acceptBox {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 45px;
        width: 45px;
        border-radius: 50%;
        background-color: green;
      }

      .rejectBox {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 45px;
        width: 45px;
        border-radius: 50%;
        background-color: red;
      }
    }
  }
}

.heightCalc {
  height: calc(68vh - 78px) !important;
  padding: 20px;

  .userCallSec {
    width: 100%;
    background: var(--Light-Blue, #e9f5fb);
    height: 600px;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
  }
}

.add-new-pet-option {
  text-transform: capitalize;
  font-style: italic;
  background-color: #1d7c7c;
  color: #fff;
  font-weight: 700;
}
//  STyles for Notification Screen\
.notificationWrapper {
  background-color: #fff;
  min-height: calc(100vh - 110px);
  padding: 20px;
  border-radius: 20px;

  .insideTable {
    width: 100%;

    .tableNoti {
      width: 100%;

      thead {
        tr {
          border-bottom: 0.5px solid #eef3fc;

          th {
            position: sticky;
            top: 0px;
            background-color: #fff;
            color: #a3aed0;
            font-size: 14px;
            padding-bottom: 5px;
            // width: 20%;

            span {
              cursor: pointer;
              font-size: 15px;
              font-weight: 600;
              color: #042486;
            }
          }
        }
      }

      tbody {
        tr {
          td {
            padding: 10px 10px 10px 0px;
            font-size: 12px;
            color: #042486;

            span {
              cursor: pointer;
              font-size: 15px;
              font-weight: 400;
            }
          }
        }
      }
    }
  }
}
