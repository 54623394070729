@media all and (max-width : 1300px) {

    // Login responsive
    .loginMain {
        .signinForm {
            margin: 45px 70px;
        }
    }

    // Registration responsive
    .registrationMain {
        .RegisterForm {
            margin: 45px 70px !important;
        }
    }

    .forgetPassword,
    .resetPassword,
    .petinformationMain {
        .signinForm {
            margin: 45px 70px !important;
        }
    }

    .getStaredmain {
        .getStarted {
            margin: 150px 70px !important;
        }
    }

    .DoctorsList {
        .cardsSections {
            .detailCardsBtns {
                flex-direction: column;

                .DetailsBtn {
                    width: 100%;
                    margin-bottom: 10px;

                    button {
                        width: 100%;
                    }
                }

                .bookBtn {
                    width: 100%;

                    button {
                        width: 100%;
                    }
                }
            }
        }
    }

    .DoctorsDetails {
        .tabsContent {
            .RatingsReviews {
                .reviewRow {
                    flex-direction: column !important;

                    .reviewCol1 {
                        .catImg {
                            margin-bottom: 10px;
                        }
                    }

                    .catImg {
                        margin-bottom: 10px;
                    }

                    .reviewCol11 {
                        width: 100%;
                    }
                }
            }
        }
    }
}

@media all and (max-width : 1200px) {
    .DoctorsList {
        .colBox90 {
            .cardsSections {
                .cardsInsider {
                    .detailsCOntent {
                        flex-direction: column;

                        .imgContainer {
                            margin-bottom: 10px;
                        }
                    }
                }
            }
        }
    }

    .medicinesList {
        .colmain9 {
            .cardsSections {
                .colmain4 {
                    width: 50%;
                }
            }
        }
    }

    .medicinesDetails {
        .reviewsBox {
            .rateCol3 {
                width: 100%;
            }

            .rateCol9 {
                width: 100%;
            }
        }
    }

    .allSellers {
        .innerSellerTab {
            .tBody {
                .twoBtns {
                    .addBtn {
                        margin-right: 0px !important;
                        margin-bottom: 10px;
                        width: 100% !important;
                    }

                    .buyBtn {
                        width: 100% !important;
                    }
                }
            }
        }
    }
}

@media all and (max-width : 991px) {
    .address-card-modal {
        width: auto;
    }
    .registrationMain,
    .forgetPassword,
    .resetPassword,
    .petinformationMain,
    .getStaredmain {
        .landingImg {
            display: none;
        }
    }

    .DoctorsList {
        .ColBox30 {
            width: 100% !important;
        }

        .colBox90 {
            width: 100%;
        }
    }

    .DoctorsDetails {
        .tabsContent {
            .nav-item {
                width: 50% !important;
            }
        }
    }

    .bookApppointment {
        .outerCol7 {
            width: 100%;
        }

        .belowBtns {
            margin-bottom: 20px;
        }

        .outerCol5 {
            display: flex;
            justify-content: space-between;
            width: 100%;

            .bookingSummary,
            .bookingCharges {
                width: 49%;
            }
        }
    }

    .medicinesList {

        .colmain3,
        .colmain9 {
            width: 100%;
        }
    }

    .medicinesDetails {

        .colMain9,
        .colMain3 {
            width: 100%;
        }

        // .reviewsBox {
        //     .rateCol3 {
        //         width: 100%;
        //         margin-bottom: 20px;

        //         .barsFlex {
        //             width: 100%;

        //             .laberate{
        //                 width: 20%;
        //             }
        //             .labelPercent {
        //                 width: 20%;
        //                 text-align: end;
        //             }
        //             .progress {
        //                 width: 80% !important;
        //             }
        //         }
        //     }

        //     .rateCol9 {
        //         width: 100%;
        //     }
        // }
    }
}

@media all and (max-width : 768px) {
    .loginMain {
        .landingImg {
            display: none;
        }
    }

    .petinformationMain {
        .signinForm {
            margin: 30px 10px 10px !important;
        }
    }

    .DoctorsDetails {
        .headingCard {
            flex-direction: column;
            align-items: start;

            .leftContent {
                width: 100%;
                margin-bottom: 10px;
            }
        }
    }

    .bookApppointment {
        .outerCol7 {
            .innerCard5 {
                margin-bottom: 20px;
            }
        }

        .outerCol5 {
            flex-direction: column;
            width: 100%;

            .bookingSummary,
            .bookingCharges {
                width: 100%;
            }
        }

        .boxModelInside {
            .appointmentStatusSlip {
                width: 100% !important;
            }
        }
    }
}

@media all and (max-width : 680px) {
    .bookApppointment {
        .col12Media {
            padding: 0px;
        }

        .appointmentStatusSlip {
            .statusContentSlip {
                .headingSumarry {
                    flex-direction: column;
                    align-items: start !important;

                    .imageNname {
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }

    .DoctorsDetails {
        .tabsContent {
            .RatingsReviews {
                .reviewRow {
                    .doctorReview {
                        flex-direction: column;
                        align-items: start;

                        .imgWrapper {
                            margin-bottom: 10px;
                        }
                    }
                }
            }
        }
    }

    .medicinesList {
        .colmain9 {
            .cardsSections {
                .colmain4 {
                    width: 100%;
                }
            }
        }
    }
}

@media all and (max-width : 580px) {

    .forgetPassword,
    .resetPassword {
        .signinForm {
            margin: 30px 10px 10px !important;
        }
    }

    .getStaredmain {
        .getStarted {
            margin: 20px !important;

        }
    }

    .DoctorsList {
        .scrollerAbove {
            .showMoreBtn {
                width: 100%;
            }
        }
    }

    .DoctorsDetails {
        .tabsContent {
            .nav-item {
                width: 100% !important;
            }

            .relatedDetails {
                .d-flex {
                    flex-direction: column !important;

                    .listingValues {
                        margin-bottom: 0px !important;
                    }
                }
            }

            .BusinessHours {
                .headerDetails {}

                .contentTxt {
                    .timingStatusSignals {
                        flex-direction: column !important;
                        align-items: start !important;
                    }
                }
            }

            .solutionFlex {
                flex-direction: column !important;
            }


        }
    }

    .bookApppointment {
        .outerCol7 {
            .consultationDetails {
                .consultCOntent {
                    .TypeSelection {
                        input[type="radio"]:checked+.selectedLabel {
                            width: 100% !important;
                        }

                        .selectedLabel {
                            width: 100%;

                            &:checked {
                                width: 100% !important;
                            }
                        }
                    }
                }
            }
        }

        .boxModelInside {
            .appointmentStatusSlip {
                padding: 20px;
            }
        }
    }

    .DoctorsDetails {
        .tabsContent {
            .RatingsReviews {
                .showMoreCol {
                    margin-top: 0px !important;
                    width: 100%;

                    button {
                        width: 100% !important;
                    }
                }
            }

            .FAQs {
                .accordion-button {
                    font-size: 14px !important;
                }

                .accordion-body {
                    .solutionFlex {
                        label {
                            font-size: 14px !important;
                        }
                    }
                }
            }
        }
    }

    .medicinesDetails {
        .reviewBoxInner {
            .flexviewTwocards {
                flex-direction: column;
                align-items: start;

                .imgHolder {
                    margin-bottom: 10px;
                }
            }
        }

        .showMOreBox {
            width: 100%;

            .showMoreBtn {
                width: 100% !important;
            }
        }
    }

    #couponModalBody {
        .modalCouponBox {
            .checkAndDiscount {
                flex-direction: column !important;

                .checkboxIn {
                    margin-bottom: 10px;
                }
            }

            .belowBottom {
                .savingsTxt {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 10px;
                }

                .applyBox {
                    width: 100%;

                    .applyBtn {
                        width: 100% !important;
                    }
                }

                flex-direction: column;
                padding: 10px !important;
                align-items: start !important;
            }
        }
    }
}

@media all and (max-width : 480px) {
    .bookApppointment {
        .appointmentStatusSlip {
            .statusContentSlip {
                .slipDetailsFlex {
                    .keyNvalues {
                        flex-direction: column;
                        align-items: start;
                    }
                }

            }
        }

        .belowBtns {
            width: 100%;

            a {
                .homeBtn {
                    width: 100% !important;
                    margin-bottom: 10px;
                }

                .appointmentBtn {
                    width: 100% !important;
                }

            }
        }
    }

    .medicinesDetails {
        padding: 20px !important;
    }

    .allSellers {
        padding: 20px;

        .allSellersBox {
            height: auto;
            flex-direction: column;
            align-items: start !important;

            .rightSideBox {
                flex-direction: column;
                align-items: start !important;

                .txt .rating {
                    text-align: start !important;
                    width: 100%;
                    margin-bottom: 10px;
                }
            }
        }
    }

    .medicineCart {
        padding: 20px;
    }
}

@media all and (max-width : 440px) {

    .forgetPassword,
    .resetPassword {
        .signinForm {
            .signInTxt {
                font-size: 20px !important;
            }
        }
    }

    .medicinesList {
        padding: 20px !important;

        .showmoreBtnBox {
            .ShowMore {
                width: 100% !important;
            }
        }
    }

    .medicinesDetails {
        .contentBox {
            .belowTxtFlex {
                flex-direction: column;
                align-items: start !important;
            }
        }
    }

    .DoctorsDetails {
        padding: 20px !important;

        .headingCard {
            align-items: center;
        }

        .leftContent {
            flex-direction: column;
            // align-items: center !important;

            .imgHolder {
                .imgInside {
                    margin-bottom: 10px;
                }
            }
        }

        .rightContent {
            width: 100%;

            .bookBtn {
                width: 100%;

                button {
                    width: 100% !important;
                }
            }
        }

        .tabsContent {
            .RatingsReviews {
                .belowBtns {
                    display: flex !important;
                    flex-direction: column !important;

                    .cancleBtn {
                        width: 100% !important;
                        margin-bottom: 10px;
                    }

                    .replyBtn {
                        width: 100% !important;
                    }
                }
            }

            .BusinessHours {
                .headerDetails {
                    flex-direction: column;
                    align-items: start !important;

                    .status {
                        margin-bottom: 10px;

                        .statusTxt {
                            text-align: start;
                        }
                    }
                }
            }

            .LocationsTab {
                .headingTxtNrate {
                    flex-direction: column;
                    align-items: start !important;
                }
            }

            .reviewsNchats {
                .headingDeta {
                    flex-direction: column;
                }
            }
        }
    }

    .bookApppointment {

        .outerCol7 {
            .selectedSlots {
                .chooseDateSec {
                    width: 100%;

                    .datePicker {
                        width: 100%;

                        .react-datepicker-wrapper {
                            width: 100% !important;

                            .react-datepicker__input-container {
                                input {
                                    width: 100% !important;

                                }
                            }
                        }
                    }
                }
            }

            .patientDetails {
                .patientContent {
                    .form-select {
                        width: 100%;
                    }
                }

                .imageGallery {
                    flex-wrap: wrap;

                    .dogImg {
                        margin-bottom: 10px;
                    }
                }
            }

            .consultationDetails {}
        }

        .belowBtns {
            .backLink {
                width: 100%;

                .backBtn {
                    width: 100%;
                    margin-bottom: 10px;
                }
            }

            .nextLink,
            .payLink {
                width: 100%;

                .nextBtn,
                .payBtn {
                    width: 100%;
                }
            }
        }

        .outerCol5 {
            .bookingSummary {
                .belowFlex {
                    flex-direction: column;
                    align-items: start !important;

                    span {
                        display: none;
                    }

                    .BookDay {
                        margin-left: 0px !important;
                    }
                }

                .belowFlexy {
                    .insiderFlex {
                        display: flex;
                        flex-direction: column;

                        span {
                            display: none;
                        }

                        .BookDay {
                            margin-left: 0px !important;
                        }
                    }
                }
            }
        }

        .bookApppointment {
            .col12Media {
                padding: 0px;
            }
        }
    }

    .medicineCart {
        .checkoutBtn {
            width: 100%;

            .checkBtn {
                width: 100% !important;
            }
        }
    }

    .medicineCheckout {
        padding: 20px;

        .belowBtns {
            .backLink {
                .backBtn {
                    width: 100% !important;
                    margin-bottom: 10px;
                }
            }

            .payLink {
                .payBtn {
                    width: 100% !important;
                }
            }
        }
    }
}