.medicinesList {
  padding: 20px 40px 20px 40px;

  #accordiansTab {
    background-color: #fff;
    border-radius: 20px;
    padding: 10px;
    margin-bottom: 20px;

    .mainHeadTxt {
      .headName {
        font-size: 20px;
        color: #1b5354;
        margin-bottom: 0px !important;
        border-bottom: 1px solid #f2fbfa;
        padding-bottom: 10px;
        padding: 1rem 1rem;
      }
    }

    .accordion {
      border: none !important;
    }

    .accordion-button {
      color: #1d7c7c;
      font-size: 16px;
    }

    .accordion-item {
      border: none !important;
      border-bottom: 1px solid #f2fbfa !important;
    }

    .accordion-button::after {
      background-image: url("../images/accordianArrow.svg");
      background-size: 15px;
      transition: all 0.3s ease;
    }

    .accordion-button:not(.collapsed)::after {
      transform: rotate(90deg) !important;
    }

    .accordion-button:not(.collapsed) {
      background-color: transparent;
      box-shadow: none !important;
    }

    .accordion-button:focus {
      box-shadow: none !important;
    }

    .checksPortion {
      .checkNlabel {
        margin-bottom: 10px;
      }

      .form-check .form-check-input {
        border: 1px solid #9ab7b8;
        box-shadow: none !important;

        &:checked {
          background-color: #1b5354;
          box-shadow: none !important;
          border: none;
        }

        &:checked ~ .form-check-label {
          color: #1b5354 !important;
          font-weight: 600;
        }
      }

      .form-check-label {
        color: #9ab7b8;
        cursor: pointer;
      }
    }

    .sliderAreas {
      .multi-range-slider .ruler {
        display: none !important;
      }

      .multi-range-slider {
        background-color: transparent !important;
        border: none !important;
        box-shadow: none !important;
        padding: 10px !important;
      }

      .multi-range-slider .thumb::before {
        background-color: #7090b0 !important;
        border: none !important;
      }

      .multi-range-slider .bar-inner {
        background-color: #1d7c7c !important;
        height: 10px !important;
        border: none !important;
        box-shadow: none !important;
      }

      .multi-range-slider .bar-right {
        background-color: #d9d9d9 !important;
        box-shadow: none !important;
      }

      .multi-range-slider .labels {
        display: none !important;
      }

      .belowSteps {
        display: flex;
        justify-content: space-between;
        align-items: center;

        label {
          font-size: 12px;
          color: #1d7c7c;
        }
      }
    }
  }

  .topFilterSection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .txtSec {
      .mainTxt {
        color: #1b5354;
        font-size: 20px;
        margin-bottom: 0px !important;

        .mutedTxt {
          color: #9ab7b8 !important;
          font-size: 12px;
        }
      }
    }

    .filterImg {
      .dropdown button {
        cursor: pointer;
        height: 30px;
        width: 30px;
        border-radius: 50%;
        background-color: #1b5354;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;

        img {
          visibility: visible !important;
        }
      }

      .dropdown-toggle:after {
        display: none;
      }

      .btn-check:checked + .btn,
      .btn.active,
      .btn.show,
      .btn:first-child:active,
      :not(.btn-check) + .btn:active {
        background-color: #1b5354;
      }

      .dropdown-menu {
        inset: 2px 10px auto auto !important;
        border-radius: 10px 0 10px 10px;
        min-width: 200px;
        border: none;
        padding: 10px;
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05),
          inset 0 -1px 0 rgba(0, 0, 0, 0.15);
      }

      .dropdown-item:focus,
      .dropdown-item:hover {
        background-color: white !important;
        // color: #8f4300 !important;
        color: rgba(143, 67, 0, 0.6) !important;
        font-size: 14px !important;
        font-weight: 400 !important;
        cursor: default;
      }

      .dropdown-item {
        .insdieFLex {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid#F2FBFA;
          padding: 10px 0px;

          .labelSort {
            color: #1b5354;
            font-size: 18px;
          }

          .labelClear {
            color: #9ab7b8;
            font-size: 14px;
          }
        }

        a:hover {
          color: #1d7c7c !important;
          font-weight: 600;
          font-size: 14px;
        }
      }

      .dropdown-item {
        font-size: 14px !important;
        font-weight: 400 !important;
        padding: 0px 15px 10px 0px !important;

        a {
          text-decoration: none;
          color: #9ab7b8;
        }

        &:last-child {
          padding: 0px 15px 0px 0px !important;
        }
      }
    }
  }

  .doctors-card {
    position: relative;
    padding: 10px 15px;
    background-color: #fff;
    border-radius: 20px;
    margin-bottom: 20px;
    .feturedUImg {
      position: absolute;
      z-index: 10;
      transform: rotate(-55deg);
      left: -17px;
      top: 7px;
      height: 80px;
      // width: 100px;
    }

    .CouponBadge {
      position: absolute;
      top: -15px;
      left: 10px;
      height: 40px;
      width: 33px;
      z-index: 10;
    }

    .img-section {
      position: relative;
      transition: background-color 0.3s ease, border-radius 0.3s ease;

      &:hover {
        transition: background-color 0.3s ease, border-radius 0.3s ease;

        &::before {
          content: "";
          position: absolute;
          top: 0;
          z-index: 1;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(17, 17, 17, 0.35);
          border-radius: 20px;
          transition: background-color 0.3s ease, border-radius 0.3s ease;

          &:hover {
            transition: background-color 0.3s ease, border-radius 0.3s ease;
          }
        }

        .book-btn {
          transition: all 0.3s ease;
          display: block;
          opacity: 1;
        }
      }

      .heart {
        position: absolute;
        top: 0;
        right: 0;
        display: inline-block;
        background-color: #fff;
        padding: 5px 7px 7px 10px;
        border-radius: 0 0 0 20px;
        z-index: 3;

        img {
          cursor: pointer;
        }
      }

      .img-dr {
        height: 220px;
        border-radius: 20px;
        width: 100%;
        object-fit: contain;
      }

      .book-btn {
        transition: all 0.3s ease;
        display: none;
        position: absolute;
        z-index: 2;
        left: 50%;
        transform: translateX(-50%);
        bottom: 20px;
        color: #fff;
        font-size: 16px;
        font-weight: 500;
        border: none;
        width: 160px;
        border-radius: 20px;
        height: 40px;
        background-color: #43beba;

        img {
          margin-left: 20px;
        }
      }

      .darkColor {
        background-color: #1d7c7c !important;
      }
    }

    .name-rating {
      .wrapper {
        display: flex;
        justify-content: space-between;
        margin-top: 13px;

        .name {
          color: var(--dark, #1d7c7c);
          font-size: 16px;
          font-weight: 500;
        }

        .left {
          a {
            .name {
              color: var(--dark, #1d7c7c);
              font-size: 16px;
              font-weight: 500;
              cursor: pointer !important;
            }
          }
        }

        .right {
          display: flex;
          align-items: center;

          img {
            margin-left: 5px;
          }
        }
      }

      .cr-price {
        margin-right: 10px;
        color: var(--table-light-text, #43beba);
        font-size: 16px;
        font-weight: 600;
      }

      .price {
        color: var(--dashboard-light-headers, #a3aed0);
        font-size: 16px;
        font-weight: 500;
        text-decoration-line: line-through;
      }

      .degree {
        color: #a3aed0;
        font-size: 14px;
        font-weight: 500;
      }
    }
  }

  .showmoreBtnBox {
    text-align: center;

    .ShowMore {
      border: none;
      background-color: #43beba;
      color: #fff;
      width: 210px;
      height: 40px;
      border-radius: 20px;
    }
  }
}

// Product Details
.medicinesDetails {
  padding: 20px 40px 20px 40px;

  .ProductDescriptionmain {
    margin-bottom: 20px;

    .productDescriptionBox {
      position: relative;
      background-color: #fff;
      padding: 20px;
      border-radius: 20px;
      margin-bottom: 20px;

      .innerCarousel {
        position: relative;

        .CouponBadge {
          position: absolute;
          width: 60px;
          height: 50px;
          top: 0px;
          left: 25px;
          z-index: 10;
        }

        .carousel .slider-wrapper {
          border: 1px solid #dde6e6;
          border-radius: 20px;
          padding: 18px;
        }

        .carousel .carousel-status {
          display: none;
        }

        .carousel {
          .carousel-slider {
            margin-top: 20px;
          }

          .control-arrow {
            opacity: 100%;
            height: 20px;
            width: 20px;
            border-radius: 50%;
            position: absolute;
            top: 50%;
            cursor: pointer;
          }

          .control-disabled.control-arrow {
            display: block !important;
          }

          .control-prev {
            background-image: url("../images/slidderLeftArrow.svg");
            left: 10px;
          }

          .control-next {
            background-image: url("../images/slidderRightArrow.svg");
            right: 10px !important;
          }

          .control-arrow:before,
          .carousel.carousel-slider .control-arrow:before {
            content: none !important;
          }

          text-align: center;

          .thumbs-wrapper {
            margin: 20px 0px 0px !important;

            .control-prev,
            .control-next {
              display: none !important;
            }
          }

          .thumb {
            border: 1px solid #dde6e6;
            padding: 13px;
            border-radius: 20px;
            width: 90px !important;
          }

          .thumbs {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0px !important;

            .selected {
            }
          }
        }
      }

      .contentBox {
        .flexBox {
          display: flex;
          justify-content: space-between;
        }

        .heading {
          margin-bottom: 20px;

          .headinTxt {
            font-size: 20px;
            color: #1b5354;
            margin-bottom: 5px !important;
          }

          .productLabl {
            font-size: 16px;
            color: #9ab7b8;
          }
        }

        .actionIcon {
          cursor: pointer;

          img {
            height: 24px;
            width: 24px;
          }
        }

        .paraBox {
          max-height: 150px;
          overflow-y: auto;
          margin-bottom: 20px;

          .insidePara {
            .detailPara {
              color: #1d7c7c;
              font-size: 14px;
              text-align: justify;
              margin-bottom: 0px !important;
            }
          }
        }

        .useBox {
          margin-bottom: 20px;

          .useTxt {
            font-size: 18px;
            color: #1b5354;
            margin-bottom: 10px;
          }

          .belowTxtFlex {
            display: flex;
            align-items: center;
            margin-bottom: 5px;

            .key,
            .value {
              color: #1d7c7c;
              font-size: 14px;
            }
          }
        }

        .storageBox {
          .headTxt {
            color: #1b5354;
            font-size: 18px;
          }

          .storeList {
            li {
              color: #1d7c7c;
              font-size: 14px;
            }
          }
        }
      }
    }
  }

  .addToBagBox {
    background-color: #fff;
    border-radius: 20px;
    padding: 20px;
    margin-bottom: 20px;

    .amountDetails {
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      .accAmnt {
        font-size: 20px;
        font-weight: 600;
        margin-right: 12px;
        color: #43beba;
      }

      .discountAmnt {
        color: #a3aed0;
        font-size: 18px;
        text-decoration: line-through;
      }
    }

    .availablityStatus {
      margin-bottom: 20px;

      label {
        height: 20px;
        width: 70px;
        border-radius: 10px;
        background-color: #01b529;
        color: #fff;
        font-size: 12px;
        text-align: center;
        padding: 2px 0px;
      }
    }

    .quantityMeter {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px;
      border: 1px solid #43beba;
      border-radius: 20px;
      margin-bottom: 10px;

      label {
        cursor: pointer;
        color: #43beba;
        font-style: 16px;
        font-weight: 600;
      }
    }

    .addTObag {
      margin-bottom: 20px;

      .bagBtn {
        background-color: #43beba;
        width: 100%;
        height: 42px;
        color: #fff;
        border-radius: 20px;
        border: none;
      }
    }

    .deliveryStatus {
      margin-bottom: 20px;

      .heaqdingBox {
        border-bottom: 1px solid #f2fbfa;
        margin-bottom: 10px;

        h1 {
          color: #1b5354;
          font-size: 16px;
          padding-bottom: 10px;
          margin-bottom: 0px;
        }
      }

      .statusInfoBox {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;

        .delieryTxt {
          font-size: 16px;
          color: #1d7c7c;
          margin-bottom: 0px !important;
          border-right: 1px solid #dde6e6;
          padding-right: 15px;
        }

        .couponDetail {
          font-size: 16px;
          color: #1d7c7c;
          margin-bottom: 0px !important;

          span {
            text-decoration: line-through;
            color: #9ab7b8;
            font-size: 14px;
          }
        }

        label {
          font-size: 12px;
          color: #9ab7b8;
        }
      }
    }

    .sellerBox {
      .heaqdingBox {
        border-bottom: 1px solid #f2fbfa;
        margin-bottom: 10px;

        h1 {
          color: #1b5354;
          font-size: 16px;
          padding-bottom: 10px;
          margin-bottom: 0px;
        }
      }

      .sellerCont {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;

        .headTxt {
          font-size: 16px;
          color: #1d7c7c;
        }

        .ratingTxt {
          font-size: 16px;
          color: #1d7c7c;
        }
      }

      .paraTxt {
        color: #1d7c7c;
        font-size: 12px;
        text-align: justify;
        margin-bottom: 5px !important;
      }

      .seeMoreLink {
        color: #1b5354;
        font-size: 12px;
      }
    }
  }

  .reviewsBox {
    background-color: #fff;
    padding: 20px;
    border-radius: 20px;
    margin-bottom: 60px;

    .ratingBarBox {
      margin-bottom: 30px;

      .headingBox {
        margin-bottom: 20px;

        h1 {
          color: #1d7c7c;
          font-size: 16px;
        }
      }

      .starFlex {
        display: flex;
        align-items: center;
        margin-bottom: 12px;

        .starsFlex {
          margin-right: 10px;

          img {
            margin-right: 3px;
          }
        }

        .starText {
          color: #1d7c7c;
          font-size: 16px;
          margin-bottom: 0px !important;
        }
      }

      .ratingBar {
        .ratingTxt {
          color: #9ab7b8;
          font-size: 14px;
          margin-bottom: 20px;
        }

        .barsFlex {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 10px;
          width: 100%;

          .laberate {
            font-size: 14px;
            color: #1d7c7c;
            width: 20%;
          }

          .labelPercent {
            font-size: 14px;
            color: #1d7c7c;
            width: 20%;
            text-align: end;
          }

          .progress {
            width: 80%;
            background: #f2fbfa;

            .progress-bar {
              background-color: #fdd835;
              border-radius: 20px;
            }
          }
        }
      }
    }
  }

  .reviewBoxInner {
    .headingBox {
      margin-bottom: 20px;

      h1 {
        color: #1d7c7c;
        font-size: 16px;
      }
    }

    .contentReviewBoxInside {
      margin-bottom: 20px;

      .flexviewTwocards {
        display: flex;
        justify-content: space-between;

        .imgHolder {
          margin-right: 20px;

          .imgInside {
            border: 1px solid rgb(233, 225, 225);
            height: 80px;
            width: 80px;
            border-radius: 20px;
          }
        }

        .contentHolder {
          background-color: #f2fbfa;
          padding: 10px 20px;
          width: 100%;
          border-radius: 20px;

          .headingFlex {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;

            .headTxt {
              color: #1b5354;
              font-size: 18px;
              margin-bottom: 0px;
            }

            .rateStar {
              color: #1d7c7c;
              font-size: 18px;
            }
          }

          .nameAndDomain {
            .daomainNdate {
              color: #9ab7b8;
              font-size: 14px;
              margin-bottom: 10px;
            }

            .domainPara {
              color: #1d7c7c;
              font-size: 16px;
              text-align: justify;
            }
          }
        }
      }
    }

    .showMOreBox {
      text-align: center;

      .showMoreBtn {
        border: none;
        height: 42px;
        width: 250px;
        background-color: #43beba;
        color: #fff;
      }
    }
  }

  .noMargin {
    margin-bottom: 0px !important;
  }

  .CardsCarousel {
    margin-bottom: 60px;

    .carouselHeading {
      position: relative;
      width: 100%;
      margin-bottom: 20px;

      .headTxt {
        color: #1b5354;
        font-size: 20px;
        font-weight: 600;
      }
    }

    .Steps2 {
      position: absolute;
      top: -18px;
      left: 142px;
    }

    .Steps {
      position: absolute;
      top: -15px;
      left: 220px;
    }

    .slick-list {
      width: 101% !important;
    }

    .slick-prev {
      left: auto !important;
      right: 35px !important;
      top: -30px;
    }

    .slick-next {
      right: 5px;
      top: -30px;
    }

    .slick-next:before {
      color: #43beba !important;
      opacity: 100% !important;
    }

    .slick-prev:before {
      color: #43beba !important;
      opacity: 100% !important;
    }

    .carosuelInside {
      //    display: flex;
      //    justify-content: space-between;
      //    align-items: center;

      .medicineCardsCarosel {
        position: relative;
        background-color: #fff;
        padding: 20px 10px;
        border-radius: 20px;
        width: 94% !important;

        .pedigree {
          height: 200px;
          width: 200px;
        }

        .innerContent {
          display: flex !important;
          flex-direction: column;
          justify-content: center !important;
          align-items: center !important;

          .heartBor {
            cursor: pointer;
          }

          .couponBadge {
            position: absolute;
            top: 0px;
            left: 30px;
          }
        }

        .mediImg {
          height: 178px;
          width: 178px;
          margin-bottom: 20px;
        }

        .heartBor {
          position: absolute;
          top: 10px;
          right: 10px;
        }

        .detailsSec {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 10px;

          .headName {
            cursor: pointer;
            color: #1b5354;
            font-style: 16px;
          }

          .flexStar {
            display: flex;
            color: #1b5354;
            font-style: 16px !important;

            .starImg {
              height: 20px;
              width: 20px;
              margin-right: 5px;
            }
          }
        }

        .rateBox {
          display: flex;
          align-items: center;

          .amntActive {
            font-size: 16px;
            color: #43beba;
            margin-right: 10px;
          }

          .mutedTxt {
            color: #a3aed0;
            font-size: 16px;
            text-decoration: line-through;
          }
        }
      }
    }
  }
}

// All Sellers
.allSellers {
  padding: 20px 40px 20px 40px;

  .innerSellerTab {
    background-color: #fff;
    padding: 20px;
    border-radius: 20px;
    overflow-x: auto;

    .scroller {
      min-width: 900px;
    }

    .tableMain {
      width: 100%;

      .tableHead {
        width: 100%;
        border-bottom: 1px solid #f2fbfa;

        tr {
          th {
            color: #9ab7b8;
            font-size: 16px;
            padding-bottom: 20px;
            font-weight: 400 !important;
          }
        }
      }

      .tBody {
        tr {
          td {
            padding: 20px 0px 10px 0px;
            border-bottom: 1px solid #f2fbfa;

            .medicalDetailsFlex {
              width: 250px;

              .ratingNname {
                .name {
                  color: #1d7c7c;
                  font-size: 16px;
                  margin: 0px 10px 10px 0px;
                }

                .starNreview {
                  color: #1d7c7c;
                  font-size: 16px;
                }
              }

              .paraTag {
                color: #1d7c7c;
                font-size: 12px;
                overflow-x: auto;
              }
            }

            .priceBox {
              width: 250px;

              .activePrice {
                font-size: 20px;
                color: #43beba;
                margin: 0px 10px 10px 0px;
              }

              .mutedTxt {
                color: #a3aed0;
                font-size: 20px;
                text-decoration: line-through;
              }

              .paraTag {
                font-size: 12px;
                color: #1d7c7c;
              }
            }

            .insidePara {
              width: 250px;

              p {
                color: #1d7c7c;
                font-size: 12px;
              }
            }

            .twoBtns {
              .addBtn {
                border: none;
                width: 160px;
                height: 42px;
                border-radius: 20px;
                background-color: #43beba;
                color: #fff;
                margin-right: 20px;
              }

              .buyBtn {
                border: none;
                width: 160px;
                height: 42px;
                border-radius: 20px;
                background-color: #1d7c7c;
                color: #fff;
              }
            }
          }
        }
      }
    }
  }
}

// Cart Styles
.medicineCart {
  padding: 20px 40px 20px 40px;

  .mainCartBox {
    .cartTable {
      background-color: #fff;
      padding: 20px;
      border-radius: 20px;
      overflow-x: auto;
      margin-bottom: 20px;

      .scroller {
        min-width: 900px;

        .table {
          .tHead {
            tr {
              th {
                padding-bottom: 10px;
                color: #9ab7b8;
                font-size: 16px;
                font-weight: 400 !important;
              }
            }
          }
        }

        .tBody {
          tr {
            td {
              padding: 20px 0px 10px 0px;

              .imgFlex {
                display: flex;
                align-items: center;

                .MediImg {
                  border: 1px solid #f2fbfa;
                  padding: 10px;
                  height: 60px;
                  width: 60px;
                  border-radius: 20px;
                  margin-right: 5px;
                }

                .Prdctname {
                  font-size: 16px;
                  color: #1d7c7c;
                }
              }

              .qntyBox {
                border: 1px solid #43beba;
                width: 120px;
                height: 42px;
                border-radius: 20px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 10px;

                .value {
                  color: #43beba;
                  font-size: 16px;
                }
              }

              .failedStatus {
                height: 24px;
                width: 24px;
                margin-top: 10px;
              }

              .rateTag {
                color: #1d7c7c;
                font-size: 16px;
                margin-top: 10px;
              }
            }
          }
        }
      }
    }

    .couponCard {
      padding: 20px;
      background-color: #fff;
      border-radius: 20px;
      margin-bottom: 20px;

      .headingFlex {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;

        h1 {
          color: #1b5354;
          font-size: 18px;
          margin-bottom: 0px;
        }

        label {
          color: #1d7c7c;
          font-size: 14px;

          img {
            margin-left: 5px;
          }
        }
      }

      .applyCouponBox {
        border: 1px dashed #43beba;
        background-color: #f2fbfa;
        border-radius: 10px;
        padding: 10px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;

        h1 {
          color: #9ab7b8;
          font-size: 16px;
          margin-bottom: 0px !important;

          img {
            margin-right: 10px;
          }
        }

        .applyBtn {
          margin-top: 5px;
          border: none;
          width: 70px;
          height: 30px;
          border-radius: 10px;
          background-color: #43beba;
          color: #fff;
          font-size: 14px;
          box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
            rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
        }
      }

      .cartSummaryBox {
        .headingFlex {
          border-top: 1px solid #f2fbfa;
          border-bottom: 1px solid #f2fbfa;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 15px 0px;
          margin-bottom: 15px;

          h1 {
            color: #1b5354;
            font-size: 18px;
          }

          label {
            color: #1d7c7c;
            font-size: 14px;
          }
        }

        .contentFlex {
          .keyValueFlex {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;

            .key {
              color: #9ab7b8;
              font-size: 16px;
            }

            .value {
              color: #1d7c7c;
              font-size: 16px;
            }
          }
        }

        .bottomCard {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 10px 0px;
          border-top: 1px solid #f2fbfa;

          .Total {
            color: #9ab7b8;
            font-size: 16px;
          }

          .TotalAmnt {
            color: #1d7c7c;
            font-size: 16px;
          }
        }
      }
    }

    .checkoutBtn {
      text-align: center;

      .checkBtn {
        border: none;
        background-color: #43beba;
        color: #fff;
        width: 260px;
        height: 42px;
        border-radius: 20px;
      }
    }
  }
}

// medicine Checkout styles
.medicineCheckout {
  padding: 20px 40px 20px 40px;

  .mainCheckout {
    .billingDetailCard {
      background-color: #fff;
      padding: 20px;
      border-radius: 20px;
      margin-bottom: 20px;

      .SelectBox {
        margin-bottom: 20px;

        .selectLabel {
          font-size: 16px;
          color: #9ab7b8;
          margin-bottom: 10px;
        }

        .form-select {
          width: 300px;
          border: none;
          border-bottom: 2px solid #1d7c7c;
          border-radius: 0px !important;
          color: #1d7c7c;
          padding-left: 0px !important;
          background-image: url("../images/accordianActiveArow.svg");

          &:focus {
            box-shadow: none;
          }
        }
      }

      .billingHead {
        border-bottom: 1px solid #f2fbfa;
        margin-bottom: 20px;

        h1 {
          color: #1b5354;
          font-size: 18px;
          margin-bottom: 0px;
          padding-bottom: 10px;
        }
      }

      .address-header {
        display: flex;
        justify-content: space-between;
        .infoTxt {
          font-size: 18px;
          color: #1d7c7c;
          margin-bottom: 20px;
          .upload_doc {
            display: flex;
          }
        }
        .address-add-btn {
          border: none;
          background-color: transparent;
          text-decoration: underline;
          color: #1d7c7c;
        }
      }

      .preDefaultDetails {
        margin-bottom: 20px;

        h1 {
          margin-bottom: 10px;
          color: #43beba;
          font-size: 16px;
        }
      }

      .detailsFiller {
        margin-bottom: 20px;

        .Tittle {
          color: #9ab7b8;
          font-size: 16px;
          margin-bottom: 10px;
        }

        .form-control {
          border: none;
          border-bottom: 2px solid #1d7c7c;
          font-size: 16px;
          border-radius: 0px;
          padding-left: 0px;

          &::placeholder {
            color: #43beba;
          }
        }
      }

      .loginAgain {
        border-bottom: 1px solid #f2fbfa;
        margin-bottom: 20px;

        .reLogin {
          color: #9ab7b8;
          padding-bottom: 20px;

          a {
            text-decoration: none;
            color: #43beba;
            font-size: 16px;
          }
        }
      }

      .checkBoxFlex {
        border-bottom: 1px solid #f2fbfa;
        display: flex;
        margin-bottom: 20px;

        .form-check-input {
          height: 16px;
          width: 16px;
          border: 1px solid #9ab7b8;
          cursor: pointer;
          margin-right: 10px;

          &:checked {
            background-size: 12px;
            background-color: #1b5354;
          }

          &:focus {
            box-shadow: none !important;
          }
        }

        .detailsNtxtarea {
          width: 100%;
          padding-bottom: 20px;
          display: flex;
          flex-direction: column;

          .shipLabel {
            margin-bottom: 10px;
            color: #9ab7b8;
            font-size: 16px;
          }

          textarea.form-control {
            border: 1px solid #43beba;
            background-color: #f2fbfa;

            &:focus {
              box-shadow: none;
            }

            &::placeholder {
              color: #43beba;
            }
          }
        }
      }

      .paymentOptions {
        background-color: #fff;
        border-radius: 20px;

        .paymentHeadin {
          margin-bottom: 20px;

          .headingTxt {
            color: #1d7c7c;
            font-size: 18px;
            margin-bottom: 0px !important;
          }
        }

        .optionList {
          .form-check {
            margin-bottom: 15px;

            .form-check-input {
              height: 20px;
              width: 20px;
              border: 1.5px solid #9ab7b8;
              margin-right: 10px;

              &:focus {
                box-shadow: none;
              }

              &:checked {
                border: 1px solid #1b5354;
                background-color: #fff;
                background-image: url("../images/greenDotRadio.svg");
                background-size: 12px;

                ~ .form-check-label {
                  color: #1d7c7c;
                }
              }
            }

            .form-check-label {
              cursor: pointer;
              color: #9ab7b8;
              font-size: 16px;
              margin-top: 3px;
            }
          }

          .upiServices {
            .formsSection {
              margin-bottom: 15px;

              .labelName {
                color: #9ab7b8;
                font-size: 16px;
                margin-bottom: 10px;
              }

              .form-control {
                border: none;
                border-bottom: 1px solid #9ab7b8;
                border-radius: 0px !important;
                padding-left: 0px !important;
                color: #1b5354;
                font-size: 16px;

                &::placeholder {
                  color: #1d7c7c;
                }

                &:focus {
                  box-shadow: none;
                }
              }
            }
          }
        }

        .nameDetails {
          margin-bottom: 20px;

          .NameLabel {
            font-size: 16px;
            color: #9ab7b8;
            margin-bottom: 10px;
          }

          .form-control {
            border: none;
            border-bottom: 2px solid #9ab7b8;
            border-radius: 0px !important;
            padding-left: 0px !important;
            color: #1d7c7c;
            font-size: 16px;

            &::placeholder {
              color: #1d7c7c;
            }

            &:focus {
              box-shadow: none;
            }
          }
        }
      }
    }

    .belowBtns {
      text-align: end;
      margin-bottom: 20px;

      .backBtn {
        border: 1px solid #43beba;
        height: 40px;
        width: 125px;
        color: #43beba;
        border-radius: 20px;
        background-color: transparent;
        margin-right: 10px;
      }

      .nextBtn {
        border: none;
        height: 40px;
        width: 125px;
        background-color: #43beba;
        border-radius: 20px;
        margin-right: 10px;
        color: #fff;

        img {
          margin-left: 10px;
        }
      }

      .payBtn {
        border: none;
        background-color: #43beba;
        color: #fff;
        width: 200px;
        height: 40px;
        border-radius: 20px;

        img {
          margin-left: 10px;
        }
      }

      // .backLink,
      // .payLink,
      // .nextLink {
      //     text-decoration: none;

      //     .backBtn {
      //         border: 1px solid #43BEBA;
      //         height: 40px;
      //         width: 125px;
      //         color: #43BEBA;
      //         border-radius: 20px;
      //         background-color: transparent;
      //         margin-right: 10px;
      //     }

      //     .nextBtn {
      //         border: none;
      //         height: 40px;
      //         width: 125px;
      //         background-color: #43BEBA;
      //         border-radius: 20px;
      //         margin-right: 10px;
      //         color: #fff;

      //         img {
      //             margin-left: 10px;
      //         }
      //     }

      //     .payBtn {
      //         border: none;
      //         background-color: #43BEBA;
      //         color: #fff;
      //         width: 200px;
      //         height: 40px;
      //         border-radius: 20px;

      //         img {
      //             margin-left: 10px;
      //         }
      //     }
      // }
    }

    .paymentOptions {
      background-color: #fff;
      border-radius: 20px;
      padding: 20px;
      margin-bottom: 20px;

      .paymentHeadin {
        border-bottom: 1px solid #f2fbfa;
        margin-bottom: 20px;

        .headingTxt {
          color: #1b5354;
          font-size: 18px;
          margin-bottom: 0px !important;
          padding-bottom: 10px;
        }
      }

      .optionList {
        .form-check {
          margin-bottom: 15px;

          .form-check-input {
            height: 20px;
            width: 20px;
            border: 1.5px solid #9ab7b8;
            margin-right: 10px;

            &:focus {
              box-shadow: none;
            }

            &:checked {
              border: 1px solid #1b5354;
              background-color: #fff;
              background-image: url("../images/greenDotRadio.svg");
              background-size: 12px;
            }
          }

          .form-check-label {
            cursor: pointer;
            color: #9ab7b8;
            font-size: 16px;
            margin-top: 3px;
          }
        }

        .upiServices {
          .formsSection {
            margin-bottom: 15px;

            .labelName {
              color: #9ab7b8;
              font-size: 16px;
              margin-bottom: 10px;
            }

            .form-control {
              border: none;
              border-bottom: 1px solid #9ab7b8;
              border-radius: 0px !important;
              padding-left: 0px !important;
              color: #1b5354;
              font-size: 16px;

              &::placeholder {
                color: #1d7c7c;
              }

              &:focus {
                box-shadow: none;
              }
            }
          }
        }
      }

      .nameDetails {
        margin-bottom: 20px;

        .NameLabel {
          font-size: 16px;
          color: #9ab7b8;
          margin-bottom: 10px;
        }

        .form-control {
          border: none;
          border-bottom: 2px solid #9ab7b8;
          border-radius: 0px !important;
          padding-left: 0px !important;
          color: #1d7c7c;
          font-size: 16px;

          &::placeholder {
            color: #1d7c7c;
          }

          &:focus {
            box-shadow: none;
          }
        }
      }
    }

    .orderSummaryCard {
      background-color: #fff;
      padding: 20px;
      border-radius: 20px;

      .orderHead {
        border-bottom: 1px solid #f2fbfa;
        margin-bottom: 20px;

        h1 {
          color: #1b5354;
          font-size: 18px;
          margin-bottom: 0px;
          padding-bottom: 10px;
        }
      }

      .headingFlex {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;

        .keyLite {
          color: #9ab7b8;
          font-style: 16px;
          margin-bottom: 10px;
        }

        .keyDark {
          color: #1d7c7c;
          font-size: 16px;
          margin-bottom: 10px;
        }
      }

      .billlingFlex {
        border-top: 1px solid #f2fbfa;
        border-bottom: 1px solid #f2fbfa;
        padding: 15px 0px;
        margin-bottom: 20px;
      }

      .darkNliteLab {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;

        .keyLite {
          color: #9ab7b8;
          font-size: 16px;
        }

        .valueDark {
          color: #1d7c7c;
          font-size: 16px;
        }
      }
    }

    .couponCard {
      padding: 20px;
      background-color: #fff;
      border-radius: 20px;
      margin-bottom: 20px;

      .headingFlex {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;

        h1 {
          color: #1b5354;
          font-size: 18px;
          margin-bottom: 0px;
        }

        label {
          color: #1d7c7c;
          font-size: 14px;

          img {
            margin-left: 5px;
          }
        }
      }

      .applyCouponBox {
        border: 1px dashed #43beba;
        background-color: #f2fbfa;
        border-radius: 10px;
        padding: 10px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;

        h1 {
          color: #9ab7b8;
          font-size: 16px;
          margin-bottom: 0px !important;

          img {
            margin-right: 10px;
          }
        }

        .applyBtn {
          margin-top: 5px;
          border: none;
          width: 70px;
          height: 30px;
          border-radius: 10px;
          background-color: #43beba;
          color: #fff;
          font-size: 14px;
          box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
            rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
        }
      }

      .cartSummaryBox {
        .headingFlex {
          border-top: 1px solid #f2fbfa;
          border-bottom: 1px solid #f2fbfa;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 15px 0px;
          margin-bottom: 15px;

          h1 {
            color: #1b5354;
            font-size: 18px;
          }

          label {
            color: #1d7c7c;
            font-size: 14px;
          }
        }

        .contentFlex {
          .keyValueFlex {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;

            .key {
              color: #9ab7b8;
              font-size: 16px;
            }

            .value {
              color: #1d7c7c;
              font-size: 16px;
            }
          }
        }

        .bottomCard {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 10px 0px;
          border-top: 1px solid #f2fbfa;

          .Total {
            color: #9ab7b8;
            font-size: 16px;
          }

          .TotalAmnt {
            color: #1d7c7c;
            font-size: 16px;
          }
        }
      }
    }
  }
}

.modal.show .modal-dialog {
  transform: none;
  place-content: center;
}
.modal-content {
  width: auto;

  .btn-close {
    display: none;
  }
}

#couponModalBody {
  display: flex;
  justify-content: center;
  .modal-content {
    margin: 0%;
  }
  .modalCouponBox {
    .headingFlex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #f2fbfa;
      padding-bottom: 20px;
      margin-bottom: 15px;

      .modalName {
        color: #1b5354;
        font-size: 18px;
        margin-bottom: 0px !important;
      }

      label {
        cursor: pointer;
      }
    }

    .couponBoxBorder {
      border: 1px dashed #43beba;
      padding: 13px;
      border-radius: 10px;
      background-color: #f2fbfa;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 15px;

      .animalPer {
        color: #1d7c7c;
        font-size: 16px;
      }

      .Check {
        color: #9ab7b8;
        font-size: 16px;
      }
    }

    .checkAndDiscount {
      border-top: 1px solid #f2fbfa;
      padding: 20px 0px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;

      .checkboxIn {
        .form-check-input {
          height: 20px;
          width: 20px;
          border: 2px solid #9ab7b8;

          &:checked {
            background-color: transparent !important;
            background-image: url("../images/checkIt.svg");
            background-size: 12px;
            border: 2px solid #43beba;
          }

          &:focus {
            box-shadow: none !important;
          }
        }
      }

      .discountBadge {
        padding: 0 22px 0 10px;
        .innBadge {
          height: 40px;
          width: 130px;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px dashed #43beba;
          border-radius: 10px;
          background-color: #bde5e1;
          color: #1d7c7c;
          font-size: 16px;
          margin-bottom: 10px;
        }

        .paraPara {
          color: #1d7c7c;
          font-size: 14px;
          margin-bottom: 0px;
        }
      }

      .savingDetails {
        width: 256px;
        .savePara {
          color: #1d7c7c;
          font-size: 14px;
          margin-bottom: 10px !important;
        }

        .belowPaara {
          color: #1d7c7c;
          font-size: 14px;
        }
      }
    }

    .belowBottom {
      padding: 20px 0px 20px 50px;
      display: flex;
      justify-content: end;
      align-items: center;
      border-top: 1px solid #f2fbfa;

      .savingsTxt {
        p {
          color: #1d7c7c;
          font-size: 14px;
          margin-bottom: 5px !important;
        }

        label {
          color: #1d7c7c;
          font-weight: 600;
          font-size: 14px;
        }
      }

      .applyBox {
        .applyBtn {
          border: none;
          background-color: #43beba;
          height: 42px;
          width: 260px;
          color: #fff;
          border-radius: 20px;
        }
      }
    }
  }
}

.bellBox,
.thumsBox {
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    height: 200px;
    width: 200px;
    margin-bottom: 20px;
  }

  p {
    color: #1b5354;
    font-weight: 600;
    font-size: 20px;
  }
}

.medicine-checkout-main-div {
  .modal-dialog {
    max-width: 1435px !important;
  }
  //     #address-modal {
  //         max-width: 1435px !important;
  //         .modal-dialog {
  //     max-width: 1435px !important;
  // }
  //     }
}
.pac-container {
  z-index: 10000;
}
.address-card-modal {
  width: 800px;
  padding: 15px;
  border-radius: 20px;
  background: var(--white, #fff);

  .submit-btn-group {
    text-align: right;

    .blank-btn {
      border-radius: 20px;
      border: 1px solid var(--table-light-text, #43beba);
      background-color: transparent;
      height: 40px;
      width: 130px;
      color: var(--table-light-text, #43beba);
      font-size: 16px;
      font-weight: 500;
    }

    .filled-btn {
      margin-left: 20px;
      border: none;
      color: #fff;
      font-size: 16px;
      font-weight: 500;
      height: 40px;
      width: 180px;
      border-radius: 20px;
      background: var(--table-light-text, #43beba);

      img {
        margin-left: 5px;
      }
    }
  }

  .heading-close-btn {
    display: flex;
    justify-content: space-between;
  }

  .form-label {
    color: var(--customer-light-heading, #9ab7b8);
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
  }

  .photograhphy {
    border-bottom: 1px solid #1d7c7c;
    padding: 0 0 2px 0;
    width: 100%;
    margin: 5px 0 0 0;
    padding-left: 6px;
  }

  .img-select {
    display: flex;
    position: relative;

    img {
      position: absolute;
      margin-top: 6px;
    }

    span {
      margin-left: 20px;
    }
  }

  .form-select {
    background-image: url(../images/accordianActiveArow.svg);
  }

  .form-select,
  .form-control {
    color: var(--dark, #1d7c7c);
    font-size: 16px;
    font-weight: 500;
    border-bottom: 1px solid #1d7c7c;
    border-top: none;
    border-left: none;
    border-right: none;
    background-color: transparent;
    border-radius: 0;
    padding: 0 0 7px 0;

    &:focus {
      box-shadow: none;
    }

    &::placeholder {
      color: var(--dark, #1d7c7c);
      font-size: 16px;
      font-weight: 500;
    }
  }

  .form-group {
    margin-bottom: 20px;

    .resend-btn-wrapper {
      display: flex;
      justify-content: space-between;

      span {
        color: var(--customer-light-heading, #9ab7b8);
        font-size: 14px;
        font-weight: 500;
      }

      button {
        background-color: transparent;
        border: none;
        color: var(--dark, #1d7c7c);
        font-size: 14px;
        font-weight: 500;
      }
    }

    .animal-img-wrapper {
      display: flex;

      .img {
        margin-right: 10px;
        position: relative;

        .cross-icon {
        }

        .animal {
          border-radius: 16px;
          height: 80px;
          width: 80px;
        }

        button {
          background: transparent;
          border: none;
          position: absolute;
          top: 3px;
          right: 3px;
          padding: 0;
        }
      }
    }
  }

  h5 {
    color: var(--dark-headings, #1b5354);
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 30px;
  }
}

// .modal-dialog {
//   max-width: 1435px !important;
// }
