.cx-header-wrapper {
  position: sticky;
  top: 0;
  z-index: 555;

  .header-top-section {
    background: #1d7c7c;

    border-bottom: 0.1px solid #d9d9d9;
    height: 40px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    .text-info {
      text-transform: capitalize;
      .circle-filled {
        margin-right: 8px;
        display: inline-block;
        height: 10px;
        width: 10px;
        border-radius: 50%;
        background-color: #d9d9d9;
      }

      label {
        color: #fff;
        font-size: 16px;
        font-weight: 500;
      }
    }
  }

  .header-bottom-section {
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
    height: 80px;
    transition: background-color 0.1s ease-in-out;

    .third {
      display: flex;
      .appointment-btn {
        .label-green {
          cursor: pointer;
          margin-left: 10px;
          color: var(--dark, #1d7c7c);
          font-size: 14px;
          font-weight: 500;
          margin-right: 20px;
        }
        .label-white {
          cursor: pointer;
          margin-left: 10px;
          color: var(--dark, #fff);
          font-size: 14px;
          font-weight: 500;
          margin-right: 20px;
          opacity: 0.86;
        }
        img {
          width: 23px;
          margin-right: 4px;
          opacity: 0.86;
        }
      }
      .btn-primary {
        background-color: transparent;
        border: none;
        padding: 0;
        margin: 0;
      }

      .dropdown-toggle::after {
        display: none;
      }

      .dropdown-menu {
        min-width: 200px;
        padding: 10px;
        border-radius: 20px 0px 20px 20px;
        border: 1px solid var(--customer-light-heading, #9ab7b8);
        background: var(--white, #fff);
        box-shadow: 14px 17px 40px 4px rgba(112, 144, 176, 0.08);
      }

      .menu {
        margin-bottom: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid #f2fbfa;

        .dropdown-item {
          padding: 0;

          &:active {
            background-color: transparent;
          }
        }

        span {
          display: flex;
          align-items: center;
          text-decoration: none;
          color: #9ab7b8;
          font-size: 14px;
          font-weight: 400;
          cursor: pointer;

          &:hover {
            background: transparent;
            transition: all 0.3s ease-in-out;
            color: var(--dark, #1d7c7c);
            font-weight: 500;
          }

          img {
            margin-right: 10px;
          }
        }
      }

      #logout {
        border-bottom: none;
        padding: 0;
      }

      .name-img-user {
        margin-bottom: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid #f2fbfa;
        display: flex;
        align-items: center;

        .left {
          img {
            border-radius: 10px;
            margin-right: 10px;
            height: 40px;
            width: 40px;
          }
        }

        .right {
          .name {
            color: var(--dark, #1d7c7c);
            font-size: 14px;
            font-weight: 500;
            text-transform: capitalize;
          }

          .animal-name {
            margin-bottom: 0;
            color: var(--customer-light-heading, #9ab7b8);
            font-size: 12px;
            font-weight: 400;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100px;
          }
        }
      }
    }

    .logo-wrapper {
      img {
        height: auto;
        width: 60px;
      }

      label {
        color: var(--dark, #1d7c7c);
        font-size: 24px;
        font-weight: 500;
      }
    }

    .search-select-wrapper {
      display: flex;
      border-radius: 20px;
      background: var(--dark-bg, #f2fbfa);
      padding: 5px 10px;

      .form-control {
        width: 480px;
        border: none;
        background: transparent;
        background: url(../images/ic_search_green.svg);
        background-position-x: right;
        background-repeat: no-repeat;
        background-position-y: 8px;
        padding-right: 30px;
        background-size: 22px;
        color: var(--dark, #1d7c7c);
        font-size: 14px;
        font-weight: 400;

        &:focus {
          box-shadow: none;
        }

        &::placeholder {
          color: var(--dark, #1d7c7c);
          font-size: 14px;
          font-weight: 400;
        }
      }

      .form-select {
        font-size: 14px;
        background-size: 20px;
        font-weight: 500;
        width: 200px;
        height: 38px;
        border-radius: 20px;
        background-color: var(--table-light-text, #43beba);
        color: #fff;
        background-image: url(../images/ic_arrow_down_white.svg);

        &:focus {
          box-shadow: none;
        }
      }
    }

    .my-ac-wrapper {
      margin-right: 20px;
      cursor: pointer;

      label {
        cursor: pointer;
        margin-left: 10px;
        color: var(--dark, #1d7c7c);
        font-size: 14px;
        font-weight: 500;
      }
    }

    .my-blog-wrapper {
      margin-right: 20px;
      display: flex;

      label {
        margin-left: 10px;
        color: var(--dark, #1d7c7c);
        font-size: 14px;
        font-weight: 500;
      }
    }

    .img-badg {
      position: relative;

      span {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        height: 24px;
        width: 24px;
        background-color: #1d7c7c;
        border-radius: 50%;
        color: #fff;
        font-size: 14px;
        font-weight: 500;
        position: absolute;
        top: -23px;
        left: 15px;
      }
    }

    .bell-wrapper {
      position: relative;
      cursor: pointer;

      .clickerNavigreen {
        .badger {
          position: absolute;
          top: -3px;
          left: 10px;
          height: 18px;
          width: 18px;
          border-radius: 50%;
          background-color: #1b5354;
          display: flex;
          justify-content: center;
          align-items: center;

          span {
            font-size: 8px;
            color: #fff;
          }
        }
      }

      .clickerNaviwhite {
        .badger {
          position: absolute;
          top: -3px;
          left: 10px;
          height: 18px;
          width: 18px;
          border-radius: 50%;
          background-color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;

          span {
            font-size: 8px;
            color: #1b5354;
          }
        }
      }
    }
  }
}

.scrollDefault {
  background-color: #fff;
  transition: background-color 0.1s ease-in-out;
}

.scrollActive {
  background-color: #1d7c7c;
  transition: background-color 0.1s ease-in-out;

  .logo-wrapper label {
    color: #f2fbfa !important;
  }

  .my-ac-wrapper label {
    color: #f2fbfa !important;
  }

  .my-blog-wrapper label {
    color: #f2fbfa !important;
  }

  .img-badg span {
    background-color: #f2fbfa !important;
    color: #1d7c7c !important;
  }
}

// Sub Header Styles
.allSellersBox {
  background-color: #fff;
  height: 80px;
  border-radius: 20px;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  .headingTxt {
    h1 {
      color: #1b5354;
      font-size: 20px;
      margin-bottom: 0px !important;
    }
  }

  .rightSideBox {
    display: flex;
    align-items: center;

    .txt {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin-right: 10px;

      .nameIn {
        color: #1b5354;
        font-size: 16px;
      }

      .rating {
        color: #1d7c7c;
        font-size: 16px;
      }
    }

    .imgHolder {
      padding: 10px;
      border: 1px solid #dce1e2;
      border-radius: 20px;
      height: 60px;
      width: 60px;
      display: flex;
      justify-content: center;
      align-items: center;

      .MediImg {
        height: 40px;
        width: 40px;
      }
    }
  }
}

.subHeaderMainBig {
  margin: auto;
  background-image: url("../header/subHeader.svg");
  width: 100%;
  background-size: cover;
  // object-fit: cover;
  border-radius: 20px;
  padding: 20px;
  height: 15vh;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;

  .landingImg {
    position: absolute;
    top: 0px;
    left: 0px;
    background: rgba($color: #43beba, $alpha: 0.8);
    width: 100%;
    border-radius: 20px;
    padding: 20px;
    height: 15vh;
  }

  h1 {
    color: #fff;
    z-index: 10;
  }
}

.subHeaderMainsmall {
  margin: auto;
  background-image: url("../header/Mask\ group.svg");
  width: 100%;
  background-size: cover;
  border-radius: 20px;
  padding: 20px;
  height: 80px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;

  .landingImg {
    position: absolute;
    top: 0px;
    left: 0px;
    background: rgba($color: #43beba, $alpha: 0.8);
    width: 100%;
    border-radius: 20px;
    padding: 20px;
    height: 80px;
  }

  h1 {
    color: #fff;
    z-index: 10;
  }
}

// Breadcrumbs styles
.breakCrumbs {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 20px;

  .BackLink {
    color: #43beba;
    font-size: 20px;
    text-decoration: none;

    .slashPower {
      margin: 0px 5px;
      color: #1b5354;
    }

    .slashPowerSame {
      margin: 0px 5px;
      color: #43beba;
    }
  }

  .frontLink {
    color: #1b5354;
    font-size: 20px;
    text-decoration: none;
  }
}
// Modal styles for coupon code
.modal-dialog {
  max-width: 660px !important;
}
.modal-cancel-btn {
  width: 140px;
  height: 40px;
  background: none;
  color: #1d7c7c;
  border: 1px solid #1d7c7c;
  border-radius: 5px;
  margin-right: 10px;
  font-size: 0.9rem;
}

.modal-create-btn {
  width: 140px;
  height: 40px;
  background-color: #1d7c7c;
  color: white;
  border-radius: 5px;
  border: none;
  font-size: 0.9rem;
}

.modal-content {
  width: 64%;
  margin: 20%;
}

@media all and (max-width: 1200px) {
  .cx-header-wrapper {
    .header-bottom-section {
      .third {
        display: flex;
        .appointment-btn {
          .label-white {
            display: none;
          }
          img {
            margin-right: 10px;
          }
        }
      }
    }
  }
}
