/*=====================================
---------------------------------------
R E S P O N S I V E 2  - CSS  - S T A R T 
---------------------------------------
=======================================*/




/*=====================================
---------------------------------------
BRAKE POINTS:-
            - max-width: 1024px
            - min-width: 768px
            - max-width: 667px            
            - max-width: 600px            
            - max-width: 568px
            - max-width: 480px
            - max-width: 375px Main Changer
            - max-width: 350px            
            - max-width: 320px            
---------------------------------------   
=======================================*/
@media all and (max-width: 1024px) {
  .DoctorsList{
    padding: 20px !important;
  }
  .main-home-wrapper .main-cards-wrapper {
    padding: 0 20px !important;
  }
}


@media all and (max-width: 1150px) {

  // .landingImg{
  //     display: none;
  // }
  .cx-header-wrapper .header-bottom-section .search-select-wrapper .form-control {
    width: 350px;
  }
}

/*================================================================================
    @media all and (max-width: 1024px) START Tab
  ================================================================================*/
@media all and (max-width: 991px) {

  // .landingImg{
  //     display: none;
  // }
  .cx-header-wrapper .header-bottom-section .search-select-wrapper .form-select {
    width: 150px;
  }

  .cx-header-wrapper .header-bottom-section .search-select-wrapper .form-control {
    width: 250px;
  }

  .cx-header-wrapper .header-top-section .text-info label {
    font-size: 12px;
  }
}

/*================================================================================
    @media all and (max-width: 1024px) End Tab
  ================================================================================*/




/*================================================================================
    @media all and (max-width: 768px) START Tab
  ================================================================================*/
@media all and (max-width : 768px) {
  .AmbulanceBook-main .booking-card-wrapper{
    flex-direction: column;
    .right{
      margin-top: 15px;
      p{
        margin-bottom: 10px !important;
      }
    }
  }
  .cx-main-wrapper{
    padding: 15px !important;
  }
  .subHeaderMainBig .landingImg{
    top: -15px;
  }
  .ProfileSetting .wrapper .wrapper-cards{
    margin-bottom: 15px;
  }
  .main-home-wrapper .Categories-wrapper .Categories-cards-wrapper .Categories-card .left .top label{
    font-size: 18px !important;
  }
  .main-home-wrapper .main-cards-wrapper h5{
font-size: 16px !important;
  }
  footer .footer-wrapper .menu-section h6{
    margin-top: 15px;
  }
  footer .footer-wrapper .menu-section ul li{
    margin-top: 10px;
  }
  .main-home-wrapper .artical-wrapper {
    padding-top: 40px;
    background-size: contain !important;
    height: auto !important;
    margin: 0 0 20px 0 !important ;
  }

  .main-home-wrapper .artical-wrapper .main-cards-wrapper .articel-card {
    margin-bottom: 15px;
  }

  .main-home-wrapper .artical-wrapper {
    height: auto;
  }

  .main-home-wrapper .top-doctors-wrapper .doctors-card-wrapper .doctors-card {
    margin-bottom: 15px;
  }

  .main-home-wrapper .Categories-wrapper .Categories-cards-wrapper .Categories-card {
    margin-bottom: 15px;
  }

  .cx-header-wrapper .header-bottom-section .my-ac-wrapper label {
    display: none;
  }

  .cx-header-wrapper .header-bottom-section .my-blog-wrapper label {
    display: none;
  }

  .signinForm {
    margin: 60px !important;
  }

  .cx-header-wrapper .header-bottom-section .search-select-wrapper .form-select {
    width: 140px;
  }

  .cx-header-wrapper .header-bottom-section .search-select-wrapper .form-control {
    width: 100%;
  }

  .cx-header-wrapper .header-top-section {
    flex-wrap: wrap;
    height: auto;
  }

  .cx-header-wrapper .header-bottom-section .logo-wrapper img {
    width: 40px;
  }

  .cx-header-wrapper .header-bottom-section {
    padding: 0 15px;
    height: 60px;
  }

  .cx-header-wrapper .header-bottom-section .my-ac-wrapper {
    margin-right: 10px;
  }

  .cx-header-wrapper .header-bottom-section .img-badg span {
    top: -15px;
  }
}

/*================================================================================
    @media all and (max-width: 768px) End Tab
  ================================================================================*/

@media all and (max-width : 600px) {
  .cx-header-wrapper .header-bottom-section .search-select-wrapper .form-select {
    display: none;
    
  }
  
  footer .social-media-wrapper{
    flex-direction: column;
    .left{
      text-align: center;
      margin-bottom: 10px;

    }
  }
  .cx-header-wrapper .header-bottom-section .search-select-wrapper .form-control {
    font-size: 12px;
    background-size: 18px;
    background-position-y: 5px;
  }
}


/*================================================================================
    @media all and (max-width: 667px) START Tab
  ================================================================================*/

/*================================================================================
    @media all and (max-width: 667px) End Tab
  ================================================================================*/




/*================================================================================
    @media all and (max-width: 600px) START Tab
  ================================================================================*/

/*================================================================================
    @media all and (max-width: 600px) End Tab
  ================================================================================*/




/*================================================================================
    @media all and (max-width: 568px) START Tab
  ================================================================================*/

/*================================================================================
    @media all and (max-width: 568px) End Tab
  ================================================================================*/




/*================================================================================
    @media all and (max-width: 480px) START Tab
  ================================================================================*/
@media all and (max-width : 768px) {
  .medicinesDetails, .medicinesList, .bookApppointment{
    padding: 15px !important;
  }
  .subHeaderMainsmall .landingImg{
    top: -15px;
  }
  .myOrders .personalOrders .reviewWrittingBox .belowBtns .submit{
    width: 100% !important;
  }
  .myOrders .personalOrders .reviewWrittingBox .belowBtns .cancel{
    width: 100% !important;
    margin-bottom: 15px;
  }
  .Label-d7-0-2-31{
    margin-top: 10px !important;
  }
  .myOrders .personalOrders .completeOrderTracker .orderDetails .mainStepper .Label-d1-0-2-11, .myOrders .personalOrders .completeOrderTracker .orderDetails .mainStepper .Label-d3-0-2-19, .myOrders .personalOrders .completeOrderTracker .orderDetails .mainStepper .Label-d5-0-2-25, .myOrders .personalOrders .completeOrderTracker .orderDetails .mainStepper .Label-d7-0-2-31{
    font-size: 10px !important;
  }
  .StepperContainer-0-2-1{
    padding: 20px 0 !important;
  }
  .myOrders .personalOrders .completeOrderTracker .orderDetails .mainStepper .statusChecker .position1{
    left: 80px !important;
    font-size: 10px !important;
  }
  .myOrders .personalOrders .completeOrderTracker .orderDetails .mainStepper .statusChecker .position2{
    font-size: 10px !important;
  }
  .myOrders .personalOrders .completeOrderTracker .orderDetails .mainStepper .statusChecker .position3{
    font-size: 10px !important;
  }
  .myOrders .personalOrders .completeOrderTracker .orderDetails .mainStepper .statusChecker .position4{
    font-size: 10px !important;
  }
  .myOrders .personalOrders .completeOrderTracker .orderDetails .mediFlex{
    margin-bottom: 15px ;
  }
  .myOrders .personalOrders .mainCard .moreActionBtns .reOrderBtn{
    margin-bottom: 15px;
  }
  .mainOrderSection .completeOrderTracker .dateBorder .DateLab{
    width: 70% !important;
  }
  .mainOrderSection .completeOrderTracker .orderDetails .bottomBtns .locationBtn{
    margin-top: 15px;
  }
  .mainOrderSection .completeOrderTracker .orderDetails .headFlex{
    flex-direction: column;
    align-items: flex-start !important;
  }
  .mainOrderSection .headingSearch .calendarFiled .form-control{
    margin-top: 15px;
    width: 100% !important;
  }
  .mainOrderSection .headingSearch{
    flex-direction: column;
  }
  .main-MyTransactionComp #transaction-search .form-control{
    width: 100% !important;
    margin-top: 15px;
  }
  .ProfileSetting .wrapper .wrapper-cards .heading-close-btn{
    flex-direction: column;
  }
  .map-with-details-wrapper .wrapper-cards .left-amb-img img{
    height: 100px !important;
    width: 100% !important;
    margin-bottom: 15px;
  }
  .map-with-details-wrapper .wrapper-cards{
    flex-direction: column;
  }
  #prescription-modal{
    overflow: auto;
  }
  #my-appointment-wrapper .app-details-wrapper .img-dr{
    margin-bottom: 10px;
  }
  #my-appointment-wrapper .app-details-wrapper{
    align-items: flex-start !important;
    flex-direction: column;
  }
  .authMain .signinForm .getStartedPara {
    width: 100%;
  }

  .getStarted {
    padding: 20px !important;
  }

  .landingImg {
    margin-top: 15px;
  }

  .signinForm {
    margin: 30px 10px 10px !important;
  }

  .authMain .signinForm .inputBox {
    margin-bottom: 23px
  }

  .authMain .signinForm .resendOtpBtn button {
    margin-top: -12px;
  }
}

/*================================================================================
    @media all and (max-width: 480px) End Tab
  ================================================================================*/
@media all and (max-width : 480px) {
  #SupportComp .heading-close-btn button{
    margin-top: 10px;
  }
  #SupportComp .heading-close-btn{
    align-items: unset !important;
  }
  .myOrders .personalOrders .completeOrderTracker .orderDetails .mainStepper .stepper .step .lower-text{
    font-size: 10px !important;
  }
  .myOrders .personalOrders .completeOrderTracker .orderDetails .mainStepper .stepper .step .upper-text{
    font-size: 10px !important;
  }
  .myOrders .personalOrders .completeOrderTracker .orderDetails .mainStepper .statusChecker .position3{
    right: 150px !important;
  }
  .myOrders .personalOrders .completeOrderTracker .orderDetails .mainStepper .statusChecker .position1{
    left: 0 !important;
  }
  .appointment-details-wrapper .btn-wrapper .btn-blank-green{
    margin-bottom: 10px;
    margin-left: 0 !important;
    width: 100% ;
  }
  .appointment-details-wrapper .img-wrapper .img{
    margin-right: 0 !important;
    
    
  }
  .appointment-details-wrapper .img-wrapper .img .animal-img{
    width: 100% !important;
    margin-bottom: 10px;

  }
  .appointment-details-wrapper .img-wrapper{
    flex-direction: column;
  }
  .ProfileSetting .wrapper .buttons-wrapper-main{
    flex-direction: column;
  }
  .DoctorsList{
    padding: 15px !important;
  }
  #sucessfull-wrapper .p-method{
    justify-content: flex-start !important;
    flex-wrap: wrap;
  }
  #sucessfull-wrapper .p-method label{
    font-size: 12px !important;
  }
  #sucessfull-wrapper .p-method p{
    font-size: 12px !important;
  }
  #sucessfull-wrapper{
    text-align: left !important;
    .top-img{
text-align: center;
    }
  }
  #sucessfull-wrapper .successfull-detail .left img{
    margin-bottom: 10px;
  }
  #sucessfull-wrapper .successfull-detail{
    flex-direction: column;
  }
  #sucessfull-wrapper #btns-grp .btn-filled{
    width: 100% !important;
    margin-top: 15px;
    margin-left: 0 !important;
  }
  #sucessfull-wrapper #btns-grp .btn-blank{
    width: 100% !important;
  }
  .petient-booking-detail-main #booking-right .left .left-left .img img{
    height: 100px !important;
  }
  .petient-booking-detail-main #booking-right #card-item{
    flex-direction: column;
  }
  .petient-booking-detail-main #btns-grp .btn-blank{
    width: 100% !important;
  }
  .petient-booking-detail-main #btns-grp .btn-filled{
    width: 100% !important;
    margin-left: 0 !important;
    margin-top: 15px;
  }
  .petient-booking-detail-main #btns-grp{
    margin: 15px 0 !important;
  }
  .AmbulanceBook-main #booking-card-wrapper .show-all-btn button{
    width: 100%;
  }
  .AmbulanceBook-main #booking-card-wrapper .rewiew-wrapper-content .rewiew-wrapper-right .nested-content img{
    height: 40px !important;
    width: 40px !important;
  }
  .AmbulanceBook-main #booking-card-wrapper .rewiew-wrapper-content .rewiew-wrapper-right .heading-star span{
    font-size: 14px !important;
  }
  .AmbulanceBook-main #booking-card-wrapper .rewiew-wrapper-content .rewiew-wrapper-right label{
    font-size: 14px !important;
  }
  .AmbulanceBook-main #booking-card-wrapper .rewiew-wrapper-content .rewiew-wrapper-right p{
    font-size: 12px !important;
  }
  .breakCrumbs .frontLink{
    font-size: 14px;
  }
  .breakCrumbs .BackLink{
    font-size: 14px;
  }
  .subHeaderMainBig{
    h1{
      font-size: 20px;
      margin-bottom: 0;
    }
  }
  .AmbulanceBook-main #booking-card-wrapper .rewiew-wrapper-content .rewiew-wrapper-left img{
    height: 50px !important;
    width: 50px !important;
    border-radius: 10px !important;
  }
  .AmbulanceBook-main .booking-card-wrapper{
    padding: 15px !important;
  }
  .AmbulanceBook-main .booking-card-wrapper .left .left-right .img-wrapper-all-ambu {
margin-top: 10px;
  }
  .AmbulanceBook-main .booking-card-wrapper .left .left-right .value{
    margin-bottom: 10px;
  }
  .AmbulanceBook-main .booking-card-wrapper .left{
    flex-direction: column;
    .left-left .img img{
      width: 100% !important;
    }
    .left-left .img{
      margin-right: 0 !important;
      margin-bottom: 10px;
    }

  }
  .main-home-wrapper .top-doctors-wrapper .doctors-card-wrapper .doctors-card .name-rating .wrapper .name{
    font-size: 14px !important;
  }
  .main-home-wrapper .top-doctors-wrapper .doctors-card-wrapper .doctors-card .name-rating .degree{
    font-size: 12px !important;
  }
  .main-home-wrapper .artical-wrapper .main-cards-wrapper .articel-card .articel-para{
    font-size: 14px !important;
  }
  .main-home-wrapper .artical-wrapper .main-cards-wrapper .articel-card .img-section .img-dr{
    height: 150px !important;
  }
  .main-home-wrapper #top-selling-wrapper .doctors-card-wrapper .doctors-card .img-section .img-dr{
    width: 100% !important;
  }
  .main-home-wrapper .top-doctors-wrapper .doctors-card-wrapper .doctors-card .img-section .img-dr{
    height: 150px !important;
  }
  .main-home-wrapper .Categories-wrapper .Categories-cards-wrapper .Categories-card{
    height: 150px !important;
  }
  .cx-header-wrapper .header-bottom-section .search-select-wrapper .form-control {
    width: 150px;

    &::placeholder {
      font-size: 12px;
    }
  }

  .authMain .signinForm .inputBox .previewPet {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
}



/*================================================================================
    @media all and (max-width: 375px) START Tab
  ================================================================================*/

/*================================================================================
    @media all and (max-width: 375px) End Tab
  ================================================================================*/




/*================================================================================
    @media all and (max-width: 350px) START Tab
  ================================================================================*/

/*================================================================================
    @media all and (max-width: 350px) End Tab
  ================================================================================*/




/*================================================================================
    @media all and (max-width: 320px) START Tab
  ================================================================================*/

/*================================================================================
    @media all and (max-width: 320px) End Tab
  ================================================================================*/