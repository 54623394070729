.authMain {
  padding: 20px;

  .signinForm {
    // margin: 0px 130px;
    margin-left: 71px;
    width: 508px;
    height: calc(100vh - 120px);
    // overflow-y: auto;

    .signInTxt {
      font-size: 30px;
      margin-bottom: 22px;
    }

    .signInPara {
      font-weight: 400;
      margin-bottom: 50px;
    }

    .youCan {
      font-weight: 400;
    }

    .registerLink {
      text-decoration: none;
      color: var(--darkBackgroundcolor1D7C7C);
      font-weight: bold;
    }

    .numberRegistration {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .requestBtn {
        height: 25px;
        border-radius: 18px;
        background-color: var(--lightGreenColorE9F5FB);
        font-size: 12px;
        color: var(--darkBackgroundcolor1D7C7C);
        outline: none;
        border: none;
        padding: 3px 17px;
        //  text-decoration: none;
      }
    }

    .terms {
      color: var(--lightGreytxtADADAD);
    }

    .resendOtpBtn button {
      color: var(--lightGreytxtADADAD);
      background-color: #1d7c7c;
      font-size: 14px;
      border: none;
      float: right;
      margin-top: -27px;
      margin-bottom: 5px;
    }

    .inputBox {
      position: relative;
      margin-bottom: 45px;

      .emailLabel {
        font-size: 13px;
        color: var(--lightGreyColor999999);
        margin-bottom: 5px;
      }

      .requestBtn {
        height: 25px;
        border-radius: 18px;
        background-color: #1d7c7c;
        color: #fff;
        // background-color: var(--lightGreenColorE9F5FB);
        // color: var(--darkBackgroundcolor1D7C7C);
        font-size: 12px;
        outline: none;
        border: none;
        padding: 3px 17px;
        //  text-decoration: none;
      }

      .mailImg {
        position: absolute;
        top: 32px;
        left: 0px;
      }

      .eyeImg {
        cursor: pointer;
        position: absolute;
        top: 38px;
        right: 0px;
      }

      .formInput {
        border: none;
        border-radius: 0px !important;
        border-bottom: 1px solid var(--lightGreyColor999999);
        padding-left: 26px;
        color: var(--darkBackgroundcolor1D7C7C);
        font-size: 16px;

        &:focus {
          box-shadow: none !important;
          border-bottom: 2px solid var(--darkBlackColor000);
        }

        &::placeholder {
          color: var(--darkBackgroundcolor1D7C7C) !important;
          font-size: 16px;
        }
      }

      .belowChecks {
        margin-top: 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .checkLbl {
          color: var(--darkBackgroundcolor1D7C7C) !important;
          font-size: 12px;
        }

        .form-check-input {
          border-color: var(--darkBackgroundcolor1D7C7C);

          &:checked {
            background-color: var(--darkBackgroundcolor1D7C7C) !important;
          }

          &:focus {
            box-shadow: none !important;
          }
        }

        .forgetPass {
          .forgetLab {
            color: var(--lightGreytxtADADAD);
            text-decoration: none;
            font-size: 12px;
          }
        }
      }

      .hiddenFileInput {
        display: none;
      }

      .photograhphy {
        border: none;
        border-radius: 0px !important;
        border-bottom: 1px solid var(--lightGreyColor999999);
        padding-left: 26px;
        color: var(--darkBackgroundcolor1D7C7C);
      }

      .previewPet {
        // padding: 10px 0px;
        padding: 22px 0px 0px 0px;
        display: flex;
        // margin-left: -8px;

        .petImages {
          position: relative;

          .bullykhan {
            padding: 0px 5px;
            width: 100px;
            height: 100px;
          }
        }

        .cross {
          position: absolute;
          top: 8px;
          left: 66px;
        }

        .petImagesblur {
          position: relative;
          cursor: pointer;

          .bullykhan {
            padding: 0px 5px;
            width: 100px;
            height: 100px;
          }

          .backcol {
            background-color: black;
            position: absolute;
            opacity: 0.5;
            width: 90px;
            height: 90px;
            margin: 5px 5px;
            border-radius: 20px;
          }

          .textback {
            position: absolute;
            top: 30%;
            left: 39%;
            color: white;
            font-weight: 600;
            font-size: 24px;
            z-index: 1;
          }
        }
      }
    }

    .login {
      width: 100%;

      .loginBtn {
        width: 100%;
        border: none;
        color: var(--whiteTxtColorfff);
        height: 52px;
        background-color: var(--darkBackgroundcolor1D7C7C);
        border-radius: 30px;
      }
    }

    .othermain {
      display: flex;
      justify-content: center;
      margin-top: 30px;

      .otherOptions {
        color: var(--greyTextColorB5B5B5);
        width: 130px;
        height: 24px;
        margin-bottom: 0px !important;
      }
    }

    .socialotherIcons {
      margin-top: 20px;
      height: 43.1155776978px;
      display: flex;
      justify-content: center;

      img {
        width: 41.46px;
        height: 41.46px;
        margin: 0px 10px 0px;
      }
    }

    .download-app-container {
      background-color: #1d7c7c;
      // height: 180px;
      border-radius: 10px;
      margin-top: 30px;
      padding: 10px 20px;
      text-align: center;

      .download-app-img {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
      }
      p {
        color: #fff;
        font-size: 18px;
        font-weight: 600;
      }

      .qr-img {
        height: 120px;
        width: 120px;
        // object-fit: cover;
      }

      .google-play-store-img {
        // height: 80px;
        // width: 80px;
        // object-fit: cover;
      }
      .verticle-line {
        height: 140px;
        width: 1.5px;
        background-color: #c1c1c1;
      }
    }

    .getStartedTitle {
      font-size: 30px;
      color: var(--darkBackgroundcolor1D7C7C);
      font-weight: 600;
    }

    .getStartedPara {
      width: 330px;
      color: var(--lightGreytxtADADAD);
      font-size: 16px;
      margin-top: 25px;
    }
  }

  .landingImg {
    background-color: var(--darkBackgroundcolor1D7C7C) !important;
    min-height: calc(100vh - 40px);
    border-radius: 13px;
    padding: 15px;
    position: relative;

    .mobileNumber {
      text-align: end;

      .callNumber {
        color: var(--whiteTxtColorfff);
        font-size: 13px;
      }
    }

    .doodleSmall {
      position: absolute;
      top: 90px;
      left: 88px;
    }

    .doodleRed {
      position: absolute;
      top: 155px;
      right: 20px;
    }

    .doodle {
      position: absolute;
      /* bottom: 80px; */
      left: 65px;
      top: 364px;
    }

    .doodleResetComp {
      position: absolute;
      left: 42%;
      top: 50%;
    }

    .insiderIcon {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .lockImg {
        // margin-top: 50px !important;
      }

      .keyImg {
        margin-top: 20px;
      }

      img {
        object-fit: cover;
        height: fit-content;
        max-width: 100%;
        margin-bottom: 30px;
        margin-top: 20px;
        height: 60vh;
      }
    }

    .landingTxt {
      // display: flex;
      // justify-content: center !important;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;

      .bottomBox {
        .anchTag {
          color: #fff;
          text-decoration: none;
          border-right: 1px solid #fff;
          padding-right: 15px;
          margin-right: 25px;
          margin-bottom: 12px;
          &:hover {
            text-decoration: underline;
          }

          &:last-child {
            border-right: none;
            padding-right: 0px;
            margin-right: 0px;
          }
        }
      }

      h1 {
        font-size: 34px;
        color: var(--lightWhiteColorFFFFFF);
        font-weight: 600;
        z-index: 1;
      }

      p {
        font-size: 17px;
        color: var(--lightWhiteColorFFFFFF);
        z-index: 1;
      }
      .privacy {
        .anchTag {
          color: #fff;
        }
      }
    }
  }

  .tAcPage {
    margin: 0px 60px;
    padding: 15px;
    background-color: #fff;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -4px rgba(0, 0, 0, 0.1);
    border-radius: 12px;

    .headTxt {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 10px;
    }

    .paraTxt {
      font-size: 16px;
      margin-bottom: 10px;
    }
  }

  .contactPg {
    // margin: 0px 30px;
    padding: 15px;
    background-color: #fff;
    // box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -4px rgba(0, 0, 0, 0.1);
    border-radius: 12px;

    .contactCardBox {
      height: calc(100vh - 200px);
      margin: 40px 10px;

      .breadCrumb {
        h1 {
          color: #1d7c7c;
          font-size: 18px;
        }

        h2 {
          color: #1d7c7c;
          font-weight: bold;
          font-size: 60px;
        }

        margin-bottom: 30px;
      }

      .contactDetails {
        .contactCard {
          padding: 20px;
          box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
            0 4px 6px -4px rgba(0, 0, 0, 0.1);
          border-radius: 12px;

          .blocks {
            display: flex;
            align-items: center;
            border-bottom: 0.5px solid #f1f4fa;
            margin-bottom: 30px;
            padding-bottom: 20px;

            &:last-child {
              margin-bottom: 0px;
            }

            .iconBox {
              height: 40px;
              width: 40px;
              border-radius: 50%;
              background-color: #f1f4fa;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-right: 20px;
            }

            .contentBox {
              .key {
                color: #1d7c7c;
                font-size: 14px;
              }

              .value {
                color: #1d7c7c;
                font-size: 20px;
                text-decoration: none;
              }
            }
          }
        }
      }

      .formBox {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .formConBox {
          .formControl {
            height: 45px;

            &::placeholder {
              color: #b0c0c9;
              font-size: 14px;
            }

            &:focus {
              box-shadow: none;
              border-color: #c1c1c1;
            }
          }

          .form-control {
            &::placeholder {
              color: #b0c0c9;
              font-size: 14px;
            }

            &:focus {
              box-shadow: none;
              border-color: #c1c1c1;
            }
          }
        }

        .submitBtn {
          height: 40px;
          width: 200px;
          border-radius: 12px;
          background-color: #1d7c7c;
          color: #fff;
          border: none;
        }
      }
    }
  }
}

.authMain {
  .RegisterForm {
    margin: 20px 130px;
    max-height: calc(100vh - 140px);
    overflow-y: auto;
    padding-left: 10px;

    .belowScroll {
    }

    .signInTxt {
      font-size: 30px;
      margin-bottom: 22px;
    }

    .signInPara {
      font-weight: 400;
      margin-bottom: 114px;
    }

    .youCan {
      font-weight: 400;
    }

    .registerLink {
      text-decoration: none;
      color: var(--darkBackgroundcolor1D7C7C);
      font-weight: bold;
    }

    .numberRegistration {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .requestBtn {
        height: 25px;
        border-radius: 18px;
        background-color: var(--lightGreenColorE9F5FB);
        font-size: 12px;
        color: var(--darkBackgroundcolor1D7C7C);
        outline: none;
        border: none;
        padding: 3px 17px;
        //  text-decoration: none;
      }
    }

    .terms {
      color: var(--lightGreytxtADADAD);
    }

    .form-check-input:checked {
      background-color: var(--darkBackgroundcolor1D7C7C);
      border-color: var(--darkBackgroundcolor1D7C7C);
    }

    .resendOtpBtn button {
      color: var(--lightGreytxtADADAD);
      font-size: 14px;
      border: none;
      float: right;
      margin-top: -27px;
      margin-bottom: 5px;
    }

    .inputBox {
      position: relative;
      margin-bottom: 40px;

      .profile-pic {
        position: absolute;
        height: 120px;
        width: 120px;
        left: 50%;
        transform: translateX(-50%);
        top: -111px;
        padding: 10px;
      }

      .profile-pic img {
        border-radius: 50%;
        box-shadow: 0px 0px 5px 0px #c1c1c1;
        cursor: pointer;
        width: 100px;
        height: 100px;
      }

      .filediv {
        display: block;
        width: 100%;
        height: 1px;
        background: var(--lightGreyColor999999);
        position: absolute;
        bottom: 10;
        left: 0;
      }

      .file_selection_name {
        position: absolute;
        left: 26px;
        top: 33px;
        color: var(--darkBackgroundcolor1D7C7C);
        cursor: pointer;
      }

      #fileInput {
        opacity: 0;
      }

      .emailLabel {
        font-size: 13px;
        color: var(--lightGreyColor999999);
        margin-bottom: 5px;
      }

      .mailImg {
        position: absolute;
        top: 32px;
        left: 0px;
      }

      .eyeImg {
        cursor: pointer;
        position: absolute;
        top: 38px;
        right: 0px;
      }

      .formInput {
        border: none;
        border-radius: 0px !important;
        border-bottom: 1px solid var(--lightGreyColor999999);
        padding-left: 26px;
        color: var(--darkBackgroundcolor1D7C7C);
        font-size: 16px;

        &:focus {
          box-shadow: none !important;
          border-bottom: 2px solid var(--darkBlackColor000);
        }

        &::placeholder {
          color: var(--darkBackgroundcolor1D7C7C) !important;
          font-size: 16px;
        }
      }

      .belowChecks {
        margin-top: 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .checkLbl {
          color: var(--darkBackgroundcolor1D7C7C) !important;
          font-size: 12px;
        }

        .form-check-input {
          border-color: var(--darkBackgroundcolor1D7C7C);

          &:checked {
            background-color: var(--darkBackgroundcolor1D7C7C) !important;
          }

          &:focus {
            box-shadow: none !important;
          }
        }

        .forgetPass {
          .forgetLab {
            color: var(--lightGreytxtADADAD);
            text-decoration: none;
            font-size: 12px;
          }
        }
      }
    }

    .login {
      width: 100%;
      margin: 30px 0px;

      .loginBtn {
        width: 100%;
        border: none;
        color: var(--whiteTxtColorfff);
        height: 52px;
        background-color: var(--darkBackgroundcolor1D7C7C);
        border-radius: 30px;
      }
    }
  }
}

// Get strated
.getStarted {
  margin: 20px 120px;

  .getStartedTitle {
    font-size: 30px;
    font-weight: 600;
    color: var(--darkBackgroundcolor1D7C7C);
    margin-bottom: 20px;
  }

  .getStartedPara {
    color: var(--lightGreytxtADADAD);
    font-size: 16px;
    text-align: justify;
    margin-bottom: 60px;
  }

  .login {
    width: 100%;

    .loginBtn {
      button {
        width: 100%;
        border: none;
        color: var(--whiteTxtColorfff);
        height: 52px;
        background-color: var(--darkBackgroundcolor1D7C7C);
        border-radius: 30px;
      }
    }
  }
}

.resendOtpActiveBtn button {
  background-color: #b0c0c9;
  font-size: 14px;
  border: none;
  float: right;
  margin-top: -27px;
  margin-bottom: 5px;
  color: #1d7c7c;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
