.DoctorsList {
  padding: 20px 40px 20px 40px;

  #accordiansTab {
    background-color: #fff;
    border-radius: 20px;
    padding: 10px;
    margin-bottom: 20px;

    .mainHeadTxt {
      .headName {
        font-size: 20px;
        color: #1b5354;
        margin-bottom: 0px !important;
        border-bottom: 1px solid #f2fbfa;
        padding-bottom: 10px;
        padding: 1rem 1rem;
      }
    }

    .accordion {
      border: none !important;
    }

    .accordion-button {
      color: #1d7c7c;
      font-size: 16px;
    }

    .accordion-item {
      border: none !important;
      border-bottom: 1px solid #f2fbfa !important;
    }

    .accordion-button::after {
      background-image: url("../images/accordianArrow.svg");
      background-size: 15px;
      transition: all 0.3s ease;
    }

    .accordion-button:not(.collapsed)::after {
      transform: rotate(90deg) !important;
    }

    .accordion-button:not(.collapsed) {
      background-color: transparent;
      box-shadow: none !important;
    }

    .accordion-button:focus {
      box-shadow: none !important;
    }

    .checksPortion {
      .checkNlabel {
        margin-bottom: 10px;
      }

      .form-check .form-check-input {
        border: 1px solid #9ab7b8;
        box-shadow: none !important;

        &:checked {
          background-color: #1b5354;
          box-shadow: none !important;
          border: none;
        }

        &:checked ~ .form-check-label {
          color: #1b5354 !important;
          font-weight: 600;
        }
      }

      .form-check-label {
        color: #9ab7b8;
        cursor: pointer;
      }
    }

    .sliderAreas {
      .multi-range-slider .ruler {
        display: none !important;
      }

      .multi-range-slider {
        background-color: transparent !important;
        border: none !important;
        box-shadow: none !important;
        padding: 10px !important;
      }

      .multi-range-slider .thumb::before {
        background-color: #7090b0 !important;
        border: none !important;
      }

      .multi-range-slider .bar-inner {
        background-color: #1d7c7c !important;
        height: 10px !important;
        border: none !important;
        box-shadow: none !important;
      }

      .multi-range-slider .bar-right {
        background-color: #d9d9d9 !important;
        box-shadow: none !important;
      }

      .multi-range-slider .labels {
        display: none !important;
      }

      .belowSteps {
        display: flex;
        justify-content: space-between;
        align-items: center;

        label {
          font-size: 12px;
          color: #1d7c7c;
        }
      }
    }
  }

  .cardsSections {
    .topFilterSection {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;

      .txtSec {
        .mainTxt {
          color: #1b5354;
          font-size: 20px;
          margin-bottom: 0px !important;

          .mutedTxt {
            color: #9ab7b8 !important;
            font-size: 12px;
          }
        }
      }

      .filterImg {
        cursor: pointer;
        height: 30px;
        width: 30px;
        border-radius: 50%;
        background-color: #1b5354;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    // .scrollerAbove{
    //     max-height: calc(100vh - 460px);
    //     overflow-y: auto;
    // }
    .cardsInsider {
      position: relative;
      background-color: #fff;
      border-radius: 20px;
      padding: 10px;
      margin-bottom: 20px;
      // height: 328px;

      .detailsCOntent {
        display: flex;
        // justify-content: space-between;
        align-items: flex-start;

        .imgContainer {
          margin-right: 10px;

          .insideImg {
            height: 200px;
            width: 200px;
          }
        }

        .detailsInside {
          width: 100%;

          .detailsTop {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 15px;

            .detailsTxt {
              .doctor-status {
                display: flex;
                font-size: 12px;
                color: #9ab7b8;
                img {
                  margin-right: 5px;
                }
              }
              .NameTxt {
                color: #1d7c7c;
                font-size: 18px;
                text-transform: capitalize;
              }

              .domainTxt {
                font-size: 14px;
                color: #9ab7b8;
              }
            }

            .detActionBtn {
              cursor: pointer;
              height: 30px;
              width: 30px;
              padding: 10px;
              border-radius: 50%;
              background-color: #1b5354;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }

          .belowCOntent {
            .locationTxt {
              font-size: 12px;
              color: #9ab7b8;
              margin-bottom: 10px;
              display: flex;
              height: 45px;
              overflow: auto;

              img {
                width: 24px;
              }
            }

            .belowTxt {
              font-size: 12px;
              color: #9ab7b8;
              margin-bottom: 10px;
            }

            .docAvailableStatus {
              color: #43beba;
              font-weight: 700;
            }
          }

          .belowMg3 {
            margin-bottom: 20px !important;
          }

          .DiffClr {
            color: #1d7c7c !important;
            font-size: 14px !important;
          }
        }
      }
    }
  }
}

// Doctors Details
.DoctorsDetails {
  padding: 20px 40px 20px 40px;

  .headingCard {
    padding: 20px;
    background-color: #fff;
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .leftContent {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .imgInside {
        height: 150px;
        width: 150px;
        margin-right: 20px;
      }

      .txtHolder {
        .headingTxt {
          color: #1d7c7c;
          font-size: 18px;
          margin-bottom: 10px !important;
          text-transform: capitalize;
        }

        .timeTxt {
          font-size: 14px;
          color: #9ab7b8;
          margin-bottom: 10px !important;
        }

        .rateTxt {
          img {
            margin-right: 5px;
          }

          font-size: 14px;
          color: #9ab7b8;
          margin-bottom: 10px !important;
        }

        .imgFlex {
          display: flex;
          justify-content: space-between;
          align-items: center;

          img {
            height: 50px;
            width: 50px;
            border-radius: 12px;
            margin-right: 10px;
          }
        }
      }
    }

    .rightContent {
      .hospitalTxt,
      .videoTxt,
      .videoTxt {
        color: #9ab7b8;

        .hopsital,
        .video {
          margin-right: 10px;
        }

        .borderHeart {
          margin-right: 10px;
          cursor: pointer;
        }
      }
    }
  }

  .tabsContent {
    background-color: #fff;
    padding: 20px;
    border-radius: 20px;

    .nav {
      border-bottom: 1px solid #9ab7b8;
    }

    .nav-item {
      width: 20% !important;
      text-align: center;
    }

    .nav-link {
      color: #9ab7b8;
    }

    .nav-pills .nav-link.active {
      background-color: transparent !important;
      border-bottom: 2px solid #1b5354;
      color: #1b5354;
      border-radius: 0px !important;
      font-weight: 600;
    }

    .overviewCard {
      padding: 20px 0px 0px;

      .headerIntro {
        margin-bottom: 30px;

        .headTxt {
          color: #1b5354;
          font-size: 18px;
          font-weight: 600;
        }

        .insidePara {
          font-size: 16px;
          color: #1d7c7c;
          text-align: justify;
        }
      }

      .relatedDetails {
        margin-bottom: 30px;

        .keyTxt {
          color: #1b5354;
          font-size: 18px;
          font-weight: 600;
        }

        .listingValues {
          li {
            color: #1d7c7c;
            font-size: 16px;
            margin-bottom: 10px;
          }
        }
      }

      .mgBelowNone {
        margin-bottom: 0px !important;

        .listingValues {
          margin-bottom: 0px !important;
        }
      }
    }

    .BusinessHours {
      padding: 50px 0px 50px 0px;
      display: flex;
      justify-content: center;
      align-items: center;

      .detailsCard {
        background-color: #f2fbfa;
        border-radius: 20px;
        padding: 20px;
        width: 600px;
        height: auto;

        .headerDetails {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid #9ab7b8;
          margin-bottom: 10px;

          .date {
            padding-bottom: 10px;

            .dayTxt {
              color: #1b5354;
              margin-bottom: 5px !important;
              font-size: 16px;
              font-weight: 600;
            }

            .dateTxt {
              color: #1b5354;
              font-size: 16px;
            }
          }

          .status {
            text-align: end;

            .statusTxt {
              color: #05cd99;
              font-size: 12px;

              img {
                margin-right: 5px;
              }
            }

            .dateTxt {
              color: #1b5354;
              font-size: 16px;
            }
          }
        }

        .contentTxt {
          .timingStatusSignals {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 20px;

            .insideKey {
              color: #43beba;
              font-size: 16px;
            }

            .insideValue {
              color: #43beba;
              font-size: 16px;
            }
          }
        }
      }
    }

    .LocationsTab {
      .headingTxtNrate {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 0px 10px 0px;
        border-bottom: 1px solid #f6f6f6;
        margin-bottom: 20px;

        .headTxt {
          color: #1b5354;
          font-weight: 600;
          font-size: 18px;
        }

        .reviewTxt {
          color: #1d7c7c;
          font-size: 14px;

          .starImg {
            margin-right: 5px;
          }
        }
      }

      .relatedDetails {
        margin-bottom: 30px;

        .keyTxt {
          color: #1b5354;
          font-size: 18px;
          font-weight: 600;
          margin-bottom: 18px;
        }

        .TabValues {
          font-size: 16px;
          color: #1d7c7c;
        }

        .directionsLink {
          text-decoration: none;

          label {
            cursor: pointer;
            color: #43beba;
            font-size: 16px;
            margin-top: 19px !important;
          }
        }

        .listingValues {
          li {
            color: #1d7c7c;
            font-size: 16px;
            margin-bottom: 10px;
          }
        }

        .photosGallery {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;

          .clinicImgs {
            height: 100px;
            width: 100px;
            border-radius: 20px;
            margin-bottom: 8px;
          }
        }
      }
    }

    .RatingsReviews {
      padding: 20px 0px;

      .catImg {
        img {
          height: 100px;
          width: 100px;
          border-radius: 20px;
        }
      }

      .reviewsNchats {
        background-color: #f2fbfa;
        padding: 20px 10px;
        border-radius: 20px;
        margin-bottom: 20px;

        .headingDeta {
          display: flex;
          justify-content: space-between;
          margin-bottom: 10px;

          .docName {
            .headTxt {
              font-size: 18px;
              color: #1b5354;
              margin-bottom: 10px;
            }

            .labDEt {
              font-size: 14px;
              color: #9ab7b8;
            }
          }

          .rates {
            .starNreview {
              color: #1d7c7c;

              .starImg {
                margin-right: 5px;
              }
            }
          }
        }

        .chatsNtxt {
          .innerPara {
            color: #1d7c7c;
            font-size: 16px;
            margin-bottom: 10px !important;
            text-align: justify;
          }

          .ReplyTxt {
            color: #1b5354;
            font-size: 16px;
            margin-bottom: 10px;
          }

          .mainTxtArea {
            margin-bottom: 10px;

            .form-control {
              border-radius: 20px !important;
              padding: 19px 30px;
              color: #9ab7b8;
              font-size: 16px;
              border: none;

              &::placeholder {
                color: #9ab7b8;
                font-size: 16px;
              }

              &:focus {
                box-shadow: none !important;
              }
            }
          }

          .belowBtns {
            text-align: end;

            .cancelLink,
            .replyLink {
              text-decoration: none;

              .cancleBtn {
                border: 1px solid #1b5354;
                height: 40px;
                font-size: 16px;
                width: 115px;
                color: #1b5354;
                background-color: transparent;
                border-radius: 20px;
                margin-right: 10px;
              }

              .replyBtn {
                border: none;
                font-size: 16px;
                height: 40px;
                width: 115px;
                color: #fff;
                background-color: #1b5354;
                border-radius: 20px;
              }
            }
          }
        }

        .doctorReview {
          display: flex;
          margin-top: 10px !important;

          .imgWrapper {
            margin-right: 10px;

            .imsideDoc {
              height: 60px;
              width: 60px;
              border-radius: 6px;
            }
          }

          .textWrapper {
            .description {
              color: #1b5354;
              font-size: 18px;
              // margin-bottom: 10px !important;
            }

            .NameDOc {
              color: #9ab7b8;
              font-size: 14px;
              margin-bottom: 10px;
            }

            .ineerPara {
              color: #1d7c7c;
              font-size: 16px;
              text-align: justify;
            }
          }
        }
      }

      .showMoreCol {
        text-align: center;
        margin-top: 30px;

        .showMoreBtn {
          border: none;
          width: 250px;
          height: 40px;
          background-color: #43beba;
          color: #fff;
          font-size: 16px;
        }
      }
    }

    .FAQs {
      padding: 20px 0px;

      .accordiansTab {
        .accordion-item {
          margin-bottom: 15px;
        }

        .accordion-item {
          border: none !important;
        }

        .accordion-collapse {
          box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
          border-bottom-left-radius: 20px;
          border-bottom-right-radius: 20px;
        }

        .accordion-button {
          box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
          background-color: #f2fbfa;
          border-radius: 20px;
          color: #1d7c7c;
          font-size: 18px;

          &:focus {
            box-shadow: none;
          }
        }

        .accordion-button:not(.collapsed) {
          border-radius: 20px 20px 0 0;
        }

        .accordion-button::after {
          background-image: url("../images/rightArrowGreen.svg");
          background-size: 30px;
        }

        .accordion-button:not(.collapsed)::after {
          transform: rotate(90deg) !important;
        }

        .accordion-body {
          .solutionFlex {
            display: flex;
            justify-content: space-between;

            label {
              color: #1d7c7c;
              font-size: 18px;
            }
          }

          .solutionFlex:first-child {
            margin-right: 5px;
          }
        }
      }
    }
  }
}

// Book Appointment styles
.bookApppointment {
  padding: 20px 40px 20px 40px;

  .selectedSlots {
    background-color: #fff;
    border-radius: 20px;
    padding: 20px;
    margin-bottom: 20px;

    .slotsHeading {
      border-bottom: 1px solid #f2fbfa;
      margin-bottom: 20px;

      .slotsTxt {
        color: #1b5354;
        font-size: 18px;
        margin-bottom: 0px !important;
        padding-bottom: 10px;
      }
    }

    .slotContent {
      .chooseDateSec {
        margin-bottom: 20px;

        .dateLabel {
          font-size: 16px;
          color: #9ab7b8;
          margin-bottom: 10px;
        }

        .datePicker {
          position: relative;

          .react-datepicker-wrapper {
            .react-datepicker__input-container {
              input {
                background-color: #fff;
                color: #1d7c7c;
                border-radius: 3px;
                cursor: pointer;
                width: 300px;
                border: none !important;
                border-bottom: 2px solid #1d7c7c !important;
                padding-left: 25px !important;

                &::placeholder {
                  color: #1d7c7c;
                  font-size: 16px;
                }

                &:focus {
                  outline: none !important;
                }
              }
            }
          }

          .innerDateImg {
            position: absolute;
            z-index: 10;
            top: 0px;
            left: 0px;
          }
        }
      }

      .slotCards {
        input[type="time"] {
          border: none;
          border-radius: 5px;
          border-bottom: #1d7c7c;
          // color: #1D7C7C;
          padding: 5px 15px;
          width: 145px;
          margin-left: 15px;
        }

        .slotSelect {
          color: #9ab7b8;
          font-size: 16px;
          margin-bottom: 10px;
        }

        .cardActive {
          position: relative;
          padding: 20px 10px;
          background-color: #f2fbfa;
          border-radius: 20px;
          cursor: pointer;

          .cardHeading {
            text-align: center;
            border-bottom: 1px solid #d0eff0;
            position: relative;
            margin-bottom: 10px;

            .headTxt {
              color: #1d7c7c;
              font-size: 16px;
              margin-bottom: 0px !important;
              padding-bottom: 10px;
            }

            .checkSig {
              position: absolute;
              top: 0px;
              right: 0px;
            }
          }

          .cardContentRadio {
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;

            .clockActiveDisabled {
              // width: 160px;
              cursor: pointer;
              // background-color: #9ab7b8;
              background-color: #1d7c7c;
              border-radius: 5px;
              padding: 10px;
              // color: #646d82;
              color: #fff;
              font-size: 14px;
              margin-bottom: 15px;

              .clockImg {
                margin-right: 10px;
              }
            }

            .clockActive {
              width: 160px;
              cursor: pointer;
              background-color: #fff;
              border-radius: 5px;
              padding: 10px;
              color: #1d7c7c;
              font-size: 14px;
              margin-bottom: 15px;

              .clockImg {
                margin-right: 10px;
              }

              .ActclockImg {
                display: none;
              }
            }
          }

          .cardContentRadio input[type="radio"]:checked + label {
            background-color: #1d7c7c;
            color: #fff;

            .clockImg {
              display: none;
            }

            .ActclockImg {
              display: inline-block;
              margin-right: 10px;
            }
          }

          .wrapperBg {
            position: absolute;
            top: 0px;
            left: 0px;
            border-radius: 20px;
            padding: 20px 10px;
            background-color: #f2fbfa;
            background-color: rgba($color: #000000, $alpha: 0.4);
            z-index: 10;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }

  .patientDetails {
    background-color: #fff;
    border-radius: 20px;
    padding: 20px;
    margin-bottom: 20px;

    .patientHeading {
      border-bottom: 1px solid #f2fbfa;
      margin-bottom: 20px;

      .patientTxt {
        color: #1b5354;
        font-size: 18px;
        margin-bottom: 0px !important;
        padding-bottom: 10px;

        span {
          color: #9ab7b8;
          font-size: 14px;
          margin-left: 7px;
        }
      }
    }

    .patientContent {
      .SelectBox {
        margin-bottom: 20px;

        .selectLabel {
          font-size: 16px;
          color: #9ab7b8;
          margin-bottom: 10px;
        }

        .form-select {
          width: 300px;
          border: none;
          border-bottom: 2px solid #1d7c7c;
          border-radius: 0px !important;
          color: #1d7c7c;
          padding-left: 0px !important;
          background-image: url("../images/accordianActiveArow.svg");

          &:focus {
            box-shadow: none;
          }
        }
      }
    }

    .reasonBox {
      margin-bottom: 20px;

      .reasonLabel {
        font-size: 16px;
        color: #9ab7b8;
        margin-bottom: 10px;
      }

      .form-control {
        border: none;
        border-bottom: 2px solid #9ab7b8;
        border-radius: 0px !important;
        padding-left: 0px !important;
        color: #1d7c7c;
        font-size: 16px;

        &::placeholder {
          color: #1d7c7c;
        }

        &:focus {
          box-shadow: none;
        }
      }
    }

    .attachmentPicBox {
      margin-bottom: 20px;

      .attachmentPic {
        font-size: 16px;
        color: #9ab7b8;
        margin-bottom: 10px;
      }

      .imgHolderLab {
        position: relative;
        padding: 5px 20px;
        width: 100%;
        border-bottom: 2px solid #9ab7b8;
        color: #1d7c7c;
        padding-left: 30px;

        img {
          position: absolute;
          top: 5px;
          left: 0px;
        }
      }
    }

    .symptomnsVox {
      margin-bottom: 20px;

      .attachmentPic {
        font-size: 16px;
        color: #9ab7b8;
        margin-bottom: 10px;
      }

      .form-control {
        border: none;
        padding: 0.31rem 0.75rem;
        border-bottom: 2px solid #9ab7b8;
        border-radius: 0px !important;
        padding-left: 0px !important;
        color: #1d7c7c;
        font-size: 16px;

        &::placeholder {
          color: #1d7c7c;
        }

        &:focus {
          box-shadow: none;
        }
      }
    }

    .imageGallery {
      display: flex;
      align-items: center;

      .imgGrids {
        position: relative;
        margin-right: 15px;

        .checkCross {
          position: absolute;
          top: 5px;
          right: 5px;
          cursor: pointer;
        }

        .dogImg {
          border-radius: 20px;
          height: 100px;
          width: 100px;
        }
      }
    }
  }

  .consultationDetails {
    background-color: #fff;
    border-radius: 20px;
    padding: 20px;
    margin-bottom: 20px;

    .consultHeading {
      border-bottom: 1px solid #f2fbfa;
      margin-bottom: 20px;

      .consultTxt {
        color: #1b5354;
        font-size: 18px;
        margin-bottom: 0px !important;
        padding-bottom: 10px;
      }
    }

    .consultCOntent {
      .cunsultationBox {
        .labelInn {
          font-size: 16px;
          color: #9ab7b8;
          margin-bottom: 10px;
        }
      }

      .TypeSelection {
        display: flex;
        flex-direction: column;

        .selectedLabel {
          cursor: pointer;
          border: 1px solid #1d7c7c;
          width: 350px;
          height: 40px;
          border-radius: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #1d7c7c;
          margin-bottom: 20px;

          .clinicImg,
          .videoImgAct {
            display: none;
          }

          .clinicImgAct,
          .videoImg {
            margin-right: 10px;
          }
        }
      }

      .TypeSelection input[type="radio"]:checked + label {
        background-color: #1d7c7c;
        width: 350px;
        height: 40px;
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        margin-bottom: 20px;

        .clinicImgAct,
        .videoImgAct {
          display: none;
        }

        .videoImgAct,
        .clinicImg {
          display: inline-flex;
          margin-right: 10px;
        }
      }
    }
  }

  .paymentOptions {
    background-color: #fff;
    border-radius: 20px;
    padding: 20px;
    margin-bottom: 20px;

    .paymentHeadin {
      border-bottom: 1px solid #f2fbfa;
      margin-bottom: 20px;

      .headingTxt {
        color: #1b5354;
        font-size: 18px;
        margin-bottom: 0px !important;
        padding-bottom: 10px;
      }
    }

    .optionList {
      .form-check {
        margin-bottom: 15px;

        .form-check-input {
          height: 20px;
          width: 20px;
          border: 1.5px solid #9ab7b8;
          margin-right: 10px;

          &:focus {
            box-shadow: none;
          }

          &:checked {
            border: 1px solid #1b5354;
            background-color: #fff;
            background-image: url("../images/greenDotRadio.svg");
            background-size: 12px;
          }
        }

        .form-check-label {
          cursor: pointer;
          color: #9ab7b8;
          font-size: 16px;
          margin-top: 3px;
        }
      }

      .upiServices {
        .formsSection {
          margin-bottom: 15px;

          .labelName {
            color: #9ab7b8;
            font-size: 16px;
            margin-bottom: 10px;
          }

          .form-control {
            border: none;
            border-bottom: 1px solid #9ab7b8;
            border-radius: 0px !important;
            padding-left: 0px !important;
            color: #1b5354;
            font-size: 16px;

            &::placeholder {
              color: #1d7c7c;
            }

            &:focus {
              box-shadow: none;
            }
          }
        }
      }
    }

    .nameDetails {
      margin-bottom: 20px;

      .NameLabel {
        font-size: 16px;
        color: #9ab7b8;
        margin-bottom: 10px;
      }

      .form-control {
        border: none;
        border-bottom: 2px solid #9ab7b8;
        border-radius: 0px !important;
        padding-left: 0px !important;
        color: #1d7c7c;
        font-size: 16px;

        &::placeholder {
          color: #1d7c7c;
        }

        &:focus {
          box-shadow: none;
        }
      }
    }
  }

  .bookingSummary {
    background-color: #fff;
    border-radius: 20px;
    padding: 20px;
    margin-bottom: 20px;

    .bookingHeadin {
      border-bottom: 1px solid #f2fbfa;
      margin-bottom: 10px;

      .headingTxt {
        color: #1b5354;
        font-size: 18px;
        margin-bottom: 0px !important;
        padding-bottom: 10px;
      }
    }

    .cardCOntent {
      .clientDetail {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        .clientImgHolder {
          margin-right: 5px;

          .clientImg {
            height: 70px;
            width: 70px;
            border-radius: 6px;
          }
        }

        .clientDetHolder {
          .clientName {
            color: #1d7c7c;
            font-weight: 600;
            font-size: 18px;
            margin-bottom: 5px !important;
          }

          .ClientDomain {
            color: #9ab7b8;
            font-size: 14px;
          }
        }
      }

      .bookingDetails {
        .headFlex {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 10px;

          .bdTxt {
            font-size: 16px;
            color: #9ab7b8;
          }

          .EditlInk {
            cursor: pointer;
            color: #43beba;
            text-decoration: none;
            font-size: 16px;
          }
        }

        .belowFlex {
          display: flex;
          align-items: center;
          margin-bottom: 10px;

          .BookTime {
            color: #1d7c7c;
            font-size: 16px;

            span {
              margin: 0px 30px;
              font-weight: 600;
            }
          }

          .BookDay {
            color: #1d7c7c;
            font-size: 16px;
            margin-left: 10px;
          }
        }

        .belowFlexy {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .insiderFlex {
            .BookTime {
              color: #1d7c7c;
              font-size: 16px;

              span {
                margin: 0px 2px;
                font-weight: 600;
              }
            }

            .BookDay {
              color: #1d7c7c;
              font-size: 16px;
              margin-left: 10px;
            }
          }

          .EditlInk {
            color: #43beba;
            text-decoration: none;
            font-size: 16px;
            cursor: pointer;
          }
        }
      }
    }
  }

  .belowBtns {
    text-align: end;

    .backBtn {
      border: 1px solid #43beba;
      height: 40px;
      width: 125px;
      color: #43beba;
      border-radius: 20px;
      background-color: transparent;
      margin-right: 10px;
    }

    .nextBtn {
      border: none;
      height: 40px;
      width: 125px;
      background-color: #43beba;
      border-radius: 20px;
      margin-right: 10px;
      color: #fff;

      img {
        margin-left: 10px;
      }
    }

    .payBtn {
      border: none;
      background-color: #43beba;
      color: #fff;
      width: 200px;
      height: 40px;
      border-radius: 20px;

      img {
        margin-left: 10px;
      }
    }

    .backLink,
    .payLink,
    .nextLink {
      text-decoration: none;

      // .backBtn {
      //     border: 1px solid #43BEBA;
      //     height: 40px;
      //     width: 125px;
      //     color: #43BEBA;
      //     border-radius: 20px;
      //     background-color: transparent;
      //     margin-right: 10px;
      // }

      // .nextBtn {
      //     border: none;
      //     height: 40px;
      //     width: 125px;
      //     background-color: #43BEBA;
      //     border-radius: 20px;
      //     margin-right: 10px;
      //     color: #fff;

      //     img {
      //         margin-left: 10px;
      //     }
      // }

      // .payBtn {
      //     border: none;
      //     background-color: #43BEBA;
      //     color: #fff;
      //     width: 200px;
      //     height: 40px;
      //     border-radius: 20px;

      //     img {
      //         margin-left: 10px;
      //     }
      // }
    }
  }

  .bookingCharges {
    background-color: #fff;
    border-radius: 20px;
    padding: 20px;
    margin-bottom: 20px;

    .bookingHeadin {
      border-bottom: 1px solid #f2fbfa;
      margin-bottom: 20px;

      .headingTxt {
        color: #1b5354;
        font-size: 18px;
        margin-bottom: 0px !important;
        padding-bottom: 10px;
      }
    }

    .bookingcontent {
      .bookingFlexin {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;

        .Key {
          font-size: 16px;
          color: #9ab7b8;
        }

        .Value {
          color: #1d7c7c;
          font-size: 16px;
        }
      }
    }

    .bookingBottom {
      border-top: 1px solid #f2fbfa;

      .bookingFlexin {
        padding-top: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;

        .Key {
          font-size: 16px;
          color: #9ab7b8;
        }

        .Value {
          color: #1d7c7c;
          font-size: 16px;
        }
      }
    }
  }

  .boxModelInside {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    .appointmentStatusSlip {
      padding: 40px 80px;
      background-color: #fff;
      border-radius: 20px;
      width: 650px;
      margin-bottom: 20px;

      .headnigStatus {
        margin-bottom: 40px;

        .ThumbsUp {
          height: 100px;
          width: 100px;
          margin-bottom: 20px;
        }

        .wellcomeTxt {
          font-size: 20px;
          font-weight: 600;
          color: #1b5354;
        }
      }

      .statusContentSlip {
        .headingSumarry {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          margin-bottom: 20px;

          .imageNname {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .DoctorSahab {
              height: 60px;
              width: 60px;
              border-radius: 6px;
              margin-right: 5px;
            }

            .nameNdomain {
              h1 {
                font-size: 18px;
                color: #1d7c7c;
                margin-bottom: 5px !important;
              }

              .domain {
                font-size: 14px;
                color: #9ab7b8;
              }
            }
          }

          .statusSignal {
            .greenStatus {
              font-size: 12px;
              color: #1d7c7c;
              margin-right: 10px;

              img {
                margin-right: 5px;
                height: 20px;
                width: 20px;
              }
            }
          }
        }
      }

      .slipDetailsFlex {
        .keyNvalues {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 10px;

          .key,
          .value {
            font-size: 16px;
            color: #1d7c7c;
          }

          // .LinkInside {
          //   color: #43beba;
          //   font-size: 16px;
          //   width: 76%;
          //   text-align: justify;
          // }
        }
        .clinic-address {
          display: flex;
          justify-content: space-between;
          margin-bottom: 10px;
          .key,
          .value {
            font-size: 16px;
            color: #1d7c7c;
          }
          .LinkInside {
            color: #43beba;
            font-size: 16px;
            width: 76%;
            text-align: justify;
          }
        }
      }
    }

    .belowBtns {
      .homeBtn {
        width: 200px;
        height: 40px;
        border-radius: 20px;
        border: 1px solid #43beba;
        background-color: transparent;
        color: #43beba;
        margin-right: 10px;
      }

      .appointmentBtn {
        width: 200px;
        height: 40px;
        border-radius: 20px;
        border: none;
        background-color: #43beba;
        color: #fff;
        margin-right: 10px;
      }
    }
  }
}
